import React, { useState, useEffect } from 'react';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
import Guidelines from '../Home/Guidelines';
import PostList from './PostList';
import Card from 'react-bootstrap/Card';
import PostSomething from './PostSomething';
import { useParams } from 'react-router-dom';
import Success from '../../components/Success';
import { endpoints } from "../../enums.js";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

// eslint-disable-next-line no-unused-vars
import { FlashAutoTwoTone } from '@material-ui/icons';

const Post = (props) => {
	const urlParams = useParams();

	const [postLists, setPostLists] = useState([]);
	const [postInfo, setPostInfo] = useState({});
	const [loading, setLoading] = useState(false);

	const [successOpen, setSuccessOpen] = useState(false);

	const uploadToS3 = async (file) => {
		try {
			const { name, type } = file;
			const regex = /\.(?!.*\.)(.*)/g;
			const abbreviatedType = regex.exec(name)[1];
			const blobData = file;
			// eslint-disable-next-line no-undef
			const apigClient = apigClientFactory.newClient(
				lambdaConfig
			);
			const currentUser = await getCurrentUser();
			const additionalParams = {
				queryParams: {
					user: currentUser,
					abbrev: encodeURIComponent(abbreviatedType),
					contentType: encodeURIComponent(type)
				}
			};
			const params = {};
			const body = {};

			const response = await apigClient.fileUploadGet(params, body, additionalParams);
			const { uploadURL, Key } = JSON.parse(response.data.url);

			// Upload to s3
			await fetch(uploadURL, {
				method: "PUT",
				body: blobData
			});

			return Key;
		} catch (err) {
			// TODO: handle error
			console.log(err);
		}
	}

	let [addedFiles, setFiles] = useState([]);

	const handleFileUpload = (e) => {
		setFiles([e])
	};

	// Posting a new post to the backend
	const postSuccessFunc = async (postDetails) => {

		setSuccessOpen(true);
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();

		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};

		let topic;
		if (postDetails.topic === "Introduce Yourself") {
			topic = 1
		} else if (postDetails.topic === "Consulting Opportunities") {
			topic = 2
		} else if (postDetails.topic === "Survivor Alliance Updates") {
			topic = 3
		} else if (postDetails.topic === "Anti-Trafficking Sector") {
			topic = 4
		} else if (postDetails.topic === "Survivor Leadership Resources") {
			topic = 5
		}

		const topicParams = {
			topicId: topic
		};

		const body = {
			post_title: postDetails.title,
			post_content: postDetails.description
		}

		apigClient.topicsTopicIdPostPost(topicParams, body, additionalParams)
			.then(async (res) => {
				getPosts()
				if (addedFiles.length > 0) {
					await Promise.all(
						addedFiles.map(async (file) => {
							const key = await uploadToS3(file);
							const fileUrl = `${endpoints.S3_BUCKET}/${key}`;

							const addFileBody = {
								file_name: file.name,
								file_source: fileUrl,
								file_type: file.type
							};

							// eslint-disable-next-line no-undef
							const apigClient = apigClientFactory.newClient(
								lambdaConfig
							);

							const additionalParams = {
								queryParams: {
									user: await getCurrentUser()
								}
							};

							const topicParams = {
								postId: res.data.id,
							};

							apigClient.postPostIdFilePost(topicParams, addFileBody, additionalParams)
								.then((res) => {
									console.log(res);
								})
								.catch((err) => {
									// TODO: handle error
									console.log(err);
								})
						})
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const closeSuccess = () => {
		setSuccessOpen(false);
	};

	const getPosts = async () => {
		setLoading(true)
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const params = {
			topicId: urlParams.TPid,
		}
		apigClient.topicsTopicIdPostsGet(params, null, additionalParams)
			.then((res) => {
				for (let i = 0; i < res.data.length; i++) {
					var date = new Date(Number(res.data[i].created_at) * 1000);
					res.data[i].created_at = date.toLocaleDateString() + " " + date.toLocaleTimeString('en-US');
				}
				var sorted = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
				setPostLists(sorted);
				setLoading(false)
			})
			.catch((err) => {
				console.log(err);
				setLoading(false)
			});
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		getPosts()
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.topicsGet(null, null, additionalParams)
			.then((res) => {
				console.log(res.data)
				var topicInfo = res.data.filter((d) => d.id === Number(urlParams.TPid));
				console.log(topicInfo)
				let date = new Date(Number(topicInfo[0].most_recent_post_date) * 1000);
				topicInfo[0].most_recent_post_date = date.toLocaleDateString() + " " + date.toLocaleTimeString('en-US');
				setPostInfo(topicInfo[0]);
			})
			.catch((err) => {
				console.log(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urlParams.TPid]);

	return (
		<div>
			<div className="home">
				<div className="row">
					<div className="home-left">
						<div className="category">
							<a href="/home" className="category category-1st">
								Topics
							</a>
							&gt;
							<span className="category category-2nd">{postInfo.title}</span>
						</div>
						<Card className="comment-desktop">
							<div id="comment-image">
								<img
									id="comment-img"
									//TODO
									src="/images/Male 2.png"
									alt="Topic Icon"
								/>
							</div>
							<div id="comment-text">
								<div className="text-date">
									<p id="comment-title">{postInfo.title} </p>
									<p className="comment-and-date post-date-desktop ">
										Most recent post: {postInfo.most_recent_post_date}
									</p>
								</div>

								<div id="comment-title-text">
									<p id="name-in-comment">{postInfo.post_count} posts</p>

									<p id="post-intro">
										{postInfo.title} here! We would love to hear from everyone.
									</p>
								</div>
							</div>
						</Card>
						<Card>
							{/* <Card.Text> */}
							<Card.Body>
								<PostSomething
									user={props.user}
									postSuccessFunc={postSuccessFunc}
									handleFileUpload={handleFileUpload}
									topic={postInfo.title}
								/>
							</Card.Body>
							{/* </Card.Text> */}
						</Card>
						<div id="post-desc">
							<ul className="ul">
								{postLists.map((post, key) => {
									return <PostList data={post} key={key} title={postInfo.title} />
								})}
							</ul>
						</div>
					</div>
					<div className="home-right">
						<Guidelines className="guideline" />
					</div>
				</div>
				<Success open={successOpen} handleClose={closeSuccess} SuccessMsg="Success! Your post has been submitted." />
			</div>
			<Dialog aria-labelledby="simple-dialog-title" open={loading}>
				<DialogTitle id="simple-dialog-title">Loading...		<CircularProgress />
				</DialogTitle>
			</Dialog>
		</div>
	);
};

export default Post;
