import React, { useState } from 'react';
import InputBox from '../../components/InputBox';
import InputPullDown from '../../components/InputPullDown';
import { countries } from '../../components/Countries';
import Button from '../../components/Button';
import Modal from 'react-modal';
import LangCheckboxes from './FormComponents/LangCheckboxes';
import HobbyCheckboxes from './FormComponents/HobbyCheckboxes';
import CheckboxTitle from './FormComponents/CheckboxTitle';
import AboutBox from '../../components/AboutBox';
//import Another from './FormComponents/Another';
import AddYourPicture from '../../components/AddYourPicture';
import FileUpload from '../DiscussionForum/FileUpload';
import GoalsCheckboxes from './FormComponents/GoalsCheckboxes';
import InterestCheckboxes from './FormComponents/InterestCheckboxes';

const CreateYourProfile = (props) => {
	//const [isOpen, setIsOpen] = useState(false);

	// function toggleModal() {
	// 	setIsOpen(!isOpen);
	// }

	const [isLangOpen, setIsLangOpen] = useState(false);
	const [isGoalOpen, setIsGoalOpen] = useState(false);
	const [isInterestOpen, setIsInterestOpen] = useState(false);
	const [isHobbyOpen, setIsHobbyOpen] = useState(false);

	function toggleLangModal() {
		setIsLangOpen(!isLangOpen);
	}
	function toggleGoalModal() {
		setIsGoalOpen(!isGoalOpen);
	}
	function toggleInterestModal() {
		setIsInterestOpen(!isInterestOpen);
	}
	function toggleHobbyModal() {
		setIsHobbyOpen(!isHobbyOpen);
	}
	const [values, setValues] = useState({
		id: '',
		image_url: '',
		user_name: '',
		occupation: '',
		country: '',
		state: '',
		about: '',
		email: '',
		whatsApp: '',
		twitter: '',
		facebook: '',
		linkedIn: '',
		instagram: '',
		addanother: '',
	});
	// const [name, setName] = useState(new Set());

	// useEffect(() => {
	// 	console.log(
	// 		{
	// 			values,
	// 		},
	// 		[],
	// 	);
	// });

	// eslint-disable-next-line no-unused-vars
	const [saved, setSaved] = useState(false);

	const handleNameChange = (event) => {
		// setValues((prevState) => {
		// 	return { ...prevState, name: event.target.value };
		// });
		setValues({ ...values, user_name: event.target.value });
		// console.log(event.target.value);
	};

	const handleOccupationChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, occupation: event.target.value });

		// setValues({ ...values, name: event.target.value });
		console.log(event.target.value);
	};

	const handleStateRegionChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, state: event.target.value });

		// setValues({ ...values, name: event.target.value });
		console.log(event.target.value);
	};
	const handleLanguageChange = (event) => {
		if (event.target.checked)
			setValues((prev) => new Set([...prev, event.target.value]));
		else
			setValues(
				(prev) => new Set([...prev].filter((x) => x !== event.target.value)),
			);

		// console.log(name);
		// console.log(event.target.value);

		// setValues({ ...values, language: event.target.value });

		// setValues({ ...values, name: event.target.value });
		// console.log(event.target.value);
	};
	const handleAboutChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, about: event.target.value });

		// setValues({ ...values, name: event.target.value });
		// console.log(event.target.value);
	};

	function onSelectChange(event) {
		setValues((prevState) => {
			return { ...prevState, country: event.target.value };
		});
		console.log(setValues);
	}

	const handleEmailChange = (event) => {
		// console.log(event.target.value);

		setValues({ ...values, email: event.target.value });

		// setValues({ ...values, name: event.target.value });
		console.log(event.target.value);
	};

	const handleWhatsAppChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, whatsapp: event.target.value });

		// setValues({ ...values, name: event.target.value });
		console.log(event.target.value);
	};

	const handleTwitterChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, twitter: event.target.value });

		// setValues({ ...values, name: event.target.value });
	};

	const handleFacebookChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, facebook: event.target.value });

		// setValues({ ...values, name: event.target.value });
		// console.log(event.target.value);
	};

	const handleLinkedInChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, linkedin: event.target.value });

		// setValues({ ...values, name: event.target.value });
	};

	const handleInstagramnChange = (event) => {
		// console.log(event.target.value);
		setValues({ ...values, instagram: event.target.value });

		// setValues({ ...values, name: event.target.value });
	};
	const [anotherList, setAnotherList] = useState([{ another: '' }]);

	const handleAnotherChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...anotherList];
		list[index][name] = value;
		setAnotherList(list);
		setValues({ ...values, another: e.target.value });
	};

	const handleRemoveClick = (index) => {
		const list = [...anotherList];
		list.splice(index, 1);
		setAnotherList(list);
	};

	const handleAddClick = () => {
		setAnotherList([...anotherList, { another: '' }]);
	};

	// const getValue = (e) => {
	// 	if (e.target.checked) setName((prev) => new Set([...prev, e.target.value]));
	// 	else
	// 		setName((prev) => new Set([...prev].filter((x) => x !== e.target.value)));

	// 	// console.log(name);
	// };

	const handleSubmit = (event) => {
		event.preventDefault();
		setSaved(true);
		// props.onCreateProfile(values);
		// setValues({
		// 	name: "",
		// 	occupation: "",
		// 	country: "",
		// 	stateRegion: "",
		// 	about: "",
		// 	email: "",
		// 	whatsapp: "",
		// 	twitter: "",
		// 	facebook: "",
		// 	linkedin: "",
		// 	instagram: "",
		// });
		console.log(values);
	};

	// const customStyles = {
	// 	content: {
	// 		top: '50%',
	// 		left: '50%',
	// 		right: 'auto',
	// 		bottom: 'auto',
	// 		marginRight: '-50%',
	// 		transform: 'translate(-50%, -50%)',
	// 	},
	// };
	// function getValues() {
	// 	setValues
	// }

	// useEffect(() => {
	// 	getValues;
	// }, []);

	return (
		<div>
			<p id="create-profile-title">Create Your Profile</p>
			<div id="visibility-top">
				<img src="/images/lock.png" id="lock" alt="lock" />
				<p id="visibility-text">
					Your profile is visible to members only. <br />
					All fields are optional.
				</p>
			</div>

			<div className="addPicture">
				<AddYourPicture text="Add your profile picture" />
			</div>

			<div className="CreateYourProfile">
				<section className="left profile-border">
					<form onSubmit={handleSubmit}>
						<p className="subtitle">Introduce Yourself</p>
						<InputBox
							title="Name"
							value={values.user_name}
							onChange={handleNameChange}
						/>

						<InputBox
							title="Occupation"
							value={values.occupation}
							onChange={handleOccupationChange}
						/>
						{/* <SelectCountry onSelectChange={onSelectChange} /> */}

						<InputPullDown
							data={countries}
							value={values.country}
							onChange={onSelectChange}
							text={'Country'}
							option={'Choose Your Country'}
							countries={true}
						/>

						<InputBox
							title="State/Region"
							value={values.state}
							onChange={handleStateRegionChange}
						/>

						<p className="input-title">Language(s)</p>
						<img
							className="choose-img"
							src="/images/language.svg"
							onClick={toggleLangModal}
							alt=""
						/>
						<Modal
							ariaHideApp={false}
							isOpen={isLangOpen}
							onRequestClose={toggleLangModal}
						>
							<CheckboxTitle
								checkboxTitle="Language(s)"
								onClick={toggleLangModal}
							/>
							<LangCheckboxes
								className="modal-box"
								onChange={handleLanguageChange}
								value={values.language}
							// onChange={(e) => getValue(e)}
							/>
						</Modal>

						<p className="input-title">Goal(s)</p>
						<img
							className="choose-img"
							src="/images/Checkbox Button.svg"
							onClick={toggleGoalModal}
							alt=""
						/>
						<Modal
							ariaHideApp={false}
							isOpen={isGoalOpen}
							onRequestClose={toggleGoalModal}
						>
							<CheckboxTitle
								checkboxTitle={'Goal(s)'}
								onClick={toggleGoalModal}
							/>
							<GoalsCheckboxes
								className="modal-box"
								toggleModal={toggleGoalModal}
							/>
						</Modal>

						<p className="input-title">Interest(s)</p>
						<img
							className="choose-img"
							src="/images/interest.svg"
							onClick={toggleInterestModal}
							alt=""

						/>
						<Modal
							ariaHideApp={false}
							isOpen={isInterestOpen}
							onRequestClose={toggleInterestModal}
						>
							<CheckboxTitle
								checkboxTitle={'Interest(s)'}
								onClick={toggleInterestModal}
							/>
							<InterestCheckboxes
								className="modal-box"
								toggleModal={toggleInterestModal}
							/>
						</Modal>

						<p className="input-title">Hobby(ies)</p>
						<img
							className="choose-img"
							src="/images/hobby_Checkbox Button_.svg"
							onClick={toggleHobbyModal}
							alt=""
						/>

						<Modal
							ariaHideApp={false}
							isOpen={isHobbyOpen}
							onRequestClose={toggleHobbyModal}
						>
							<CheckboxTitle checkboxTitle="Hobby(ies)" onClick={toggleHobbyModal} />
							<HobbyCheckboxes
								className="modal-box"
								toggleModal={toggleHobbyModal}
							/>
						</Modal>

						<AboutBox
							id="about-input"
							value={values.about}
							onChange={handleAboutChange}
						/>
						<FileUpload />
					</form>
				</section>

				<section className="right profile-border ">
					<form onSubmit={handleSubmit}>
						<div className="subtitle">Contact Information</div>
						<div className="visibility">
							<img src="/images/lock.png" id="lock" alt="lock" />
							<p id="visibility-text">
								Contact information becomes visible only when the connection
								request is accepted.
							</p>
						</div>{' '}
						<InputBox
							title="Email"
							value={values.email}
							onChange={handleEmailChange}
						/>
						<InputBox
							title="WhatsApp"
							value={values.whatsapp}
							onChange={handleWhatsAppChange}
						/>
						<InputBox
							title="Twitter"
							value={values.twitter}
							onChange={handleTwitterChange}
						/>
						<InputBox
							title="Facebook"
							value={values.facebook}
							onChange={handleFacebookChange}
						/>
						<InputBox
							title="LinkedIn"
							value={values.linkedin}
							onChange={handleLinkedInChange}
						/>
						<InputBox
							title="Instagram"
							value={values.instagram}
							onChange={handleInstagramnChange}
						/>
						<p className="input-title">Add another</p>
						<div className="another">
							{anotherList.map((x, i) => {
								return (
									<div className="another-box" id={props.addId}>
										<input
											name="another"
											className="input-box "
											value={x.another}
											onChange={(e) => handleAnotherChange(e, i)}
										/>

										<div className="btn-box">
											{anotherList.length !== 1 && (
												<button
													className="another-btn"
													onClick={() => handleRemoveClick(i)}
												>
													Remove
												</button>
											)}
											{anotherList.length - 1 === i && (
												<button
													className="another-btn"
													onClick={handleAddClick}
												>
													Add
												</button>
											)}
										</div>
									</div>
								);
							})}
						</div>
						{/* <Another
							title="another"
							value={values.another}
							onChange={handleAnotherChange}
						/> */}
						<Button type="submit" id="save-btn" btnText="Save"></Button>
					</form>
				</section>
			</div>
		</div>
	);
};

export default CreateYourProfile;
