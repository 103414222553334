import Button from '../../components/Button';
import './Guidelines.css';
import React from 'react';

const Guidelines = (props) => {
	return (
		<div className={window.innerWidth < 1440 ? "guidelines-wrapper" : 'guidelines'}>
			<div className="guidelines-header">
				<div className="guidelines-title">Our Guidelines</div>
				{window.innerWidth < 1220 ? (
					<img
						className="guidelines-x"
						src="/images/Icon Close.svg"
						onClick={props.toggleModal}
						alt="x"
					/>
				)
					: null}
			</div>
			<div className="guidelines-content">
				<p>
					This is a safe space for survivors to connect with each other. Only
					survivors are admitted to the community. Your profile and posts are
					only visible to other members.
				</p>
				<p>
					Use this forum to discuss with other survivors about your past and
					current experiences, to support each other and work towards a better
					future.
				</p>
				<p>
					As a reminder, we ask that you follow our <a href="https://www.survivoralliance.org/membership" target="_blank" rel="noreferrer"><b>Membership Manifesto</b></a>{' '}
					and be respectful of one another.
				</p>
			</div>

			<div className="guidelines-btn">
				<a href="https://www.survivoralliance.org/dts-guidelines" target="_blank" rel="noreferrer">
					<Button
						type="submit"
						id="guidelines-btn"
						btnText={'DTS Guidelines'}
					/>
				</a>
			</div>
		</div>
	);
};

export default Guidelines;

// Mobil version and more css needed
