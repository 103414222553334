import React, { useEffect } from 'react';
import Button from '../../components/Button';
import getCurrentUser from "../../utils.js";
import lambdaConfig from "../../aws-exports-lambda.js";
import { statusEnum } from "../../enums.js";


const Contact = (props) => {
	const contactTypes = [
		"email",
		"whatsapp",
		"twitter",
		"facebook",
		"linkedin",
		"instagram"
	];

	const customContacts = props.data.contact || [];

	const displayContact = (contact, id) => {
		return (
			props.data[contact] ?
				<div className="contact-description" key={id}>
					<label className="contact-label">{contact}</label>
					<span>{props.data[contact]}</span>
				</div>
				: <div key={id}></div>
		)
	}

	return (
		<div id="contact-container">
			{contactTypes.map((type, id) => displayContact(type, id))}
			{customContacts.map((contact, id) => (
				<div className="contact-description" key={`custom-${id}`}>
					<label className="contact-label">
						{contact.title}
					</label>
					<span>
						{contact.contact_info}
					</span>
				</div>
			))}
		</div>
	)
};

const ContactInfo = (props) => {
	const accepted = props.member.status === statusEnum.ACCEPTED
	const [pending, setPending] = React.useState(false);

	useEffect(() => {
		setPending(props.member.status === statusEnum.PENDING)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])



	const sendRequest = async (e) => {
		e.preventDefault();
		try {
			// eslint-disable-next-line no-undef
			const apigClient = apigClientFactory.newClient(
				lambdaConfig
			);

			const additionalParams = {
				queryParams: {
					user: await getCurrentUser()
				}
			}

			const params = {
				connectionId: props.member.id
			};

			const body = {
				status: statusEnum.PENDING
			};

			apigClient.connectionsConnectionIdPost(params, body, additionalParams)
				.then((res) => {
					setPending(true)
					props.getMembers()
				})
				.then((err) => {
					setPending(false)
					console.log(err)
				});
		} catch (err) {
			// TODO: handle error
			console.log(err);
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();

		props.postSuccessFunc(); // Should call a function from parent upon API.post function .then(success)
	};

	return (
		<div className="contact-info ">
			<p className="subtitle ">Contact Information</p>
			{accepted ?
				<Contact data={props.member} />
				:
				(
					(
						(
							<div>
								{pending ?
									<div>
										<ContactBtn />
										<div className=" contact-info-btn about-desc ">
											<p>Your request had been submitted.</p>
										</div>
									</div>
									:
									<div>
										<ContactBtn />
										<div className=" contact-info-btn about-desc ">
											<Button
												className=" contact-info-btn "
												btnText="Connect"
												// onClick={sendRequest}
												onClick={(e) => {
													sendRequest(e);
													submitHandler(e); // TODO: When you have the api call for the database remove this line: submitHandler(e)  */ }
												}}
											/>
										</div>
									</div>
								}
							</div>
						)
					)
				)
			}
		</div>
	);
};

const ContactBtn = () => (
	<div className="visibility" id="connect-visibility">
		<img src="/images/lock.png" id="connect-lock-mobile" alt="lock" />
		<p id="visibility-text">
			The contact information becomes visible only when this connection request
			is accepted.
		</p>
	</div>
);

export default ContactInfo;
