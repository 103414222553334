import React, {useState} from 'react';
import RequestBadge from './RequestBadge';
import { statusEnum } from "../../enums.js";
// import lambdaConfig from "../../aws-exports-lambda.js";
// import getCurrentUser from "../../utils";
import Modal from 'react-modal';
import MemberAboutMeMobile from '../Members/MemberAboutMeMobile';
import MemberAboutMeDesktop from '../Members/MemberAboutMeDesktop ';

const ConnectionRequests = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [mem, setMem] = useState({});

	function toggleModalOpen(member) {
		setIsOpen(!isOpen);
		// console.log(e);
		// let temp = props.data.filter((m) => m.user_name === e.target.outerText);
		setMem(member);
	}

	function toggleModalClose() {
		setIsOpen(!isOpen);
		setMem({});
	}

	const connectionRequests = props.members.filter(
		(member) =>
			member.status === statusEnum.PENDING &&
			member.id !== props.currentUser.id &&
			member.action_user_id !== props.currentUser.id
	);

	const connectionsSent = props.members.filter(
		(member) =>
			member.status === statusEnum.PENDING &&
			member.id !== props.currentUser.id &&
			member.action_user_id === props.currentUser.id
	);

	return (
		<div className="desktop guidelines">
			{/* <img
				src="/images/Icon Close.svg"
				onRequestClose={props.toggleModal}
				onClick={props.toggleModal}
				alt="x"
				id="x"
			/> */}
			<div className="request ">
				<div className="guidelines-title">Connection Requests</div>
				<RequestBadge request_count={connectionRequests.length} />
			</div>
			<div id="visibility-connection">
				<img src="/images/lock.png" id="lock" alt="lock" />
				<p id="visibility-text">
					Connections are able to see your contact information.
				</p>
			</div>

			<div className="parent">
				{connectionRequests.map((member, key) => {
					return (
						<div id="sp" className="card-text" key={key} onClick={() => toggleModalOpen(member)}>
							<img
								className="request-img"
								src={member.image_url ? member.image_url : '/images/Male 2.png'}
								alt="User Profile"
								style={{width: '50px', height: '50px', borderRadius: '100%'}}
							/>
							<p className="topic-title">
								<b>
									{member.user_name}
									{/* Jill */}
								</b>
							</p>
							<button onClick={() => { props.updateConnection(member.id, statusEnum.ACCEPTED) }}>
								Accept
							</button>
							<button className="secondary" onClick={() => { props.updateConnection(member.id, statusEnum.REJECTED) }}>
								Deny
							</button>
						</div>
					);
				})}
				{connectionsSent ? (
					<div>
					<div className="guidelines-header">
						<div id="btm16" className="guidelines-title ">
							Pending Responses
						</div>
					</div>
					{connectionsSent.map((member, key) => {
						return (
							<div id="sp" className="card-text" key={key} style={{alignItems: 'center'}}>
								<img
									className="request-img"
									src={member.image_url ? member.image_url : '/images/Male 2.png'}
									alt="User Profile"
									style={{width: '50px', height: '50px', borderRadius: '100%'}}
								/>
								<p className="topic-title">
									<b>
										{member.user_name}
									</b>
								</p>
							</div>
						);
					})}
					</div>
				) : null}
				
			</div>
			<Modal
				id="member-modal"
				isOpen={isOpen}
				onRequestClose={toggleModalClose}
				ariaHideApp={false}
			>
				<MemberAboutMeMobile me={mem} toggleModal={toggleModalClose} languages={props.languages} goals={props.goals} interests={props.interests} hobbies={props.hobbies}/>
				<MemberAboutMeDesktop
					id="post-modal"
					me={mem}
					toggleModal={toggleModalClose}
					languages={props.languages} goals={props.goals} interests={props.interests} hobbies={props.hobbies}
				/>
			</Modal>
		</div>
	);
};

export default ConnectionRequests;
