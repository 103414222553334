const AboutBox = (props) => {
	return (
		<div>
			<p className="input-title">About</p>
			<textarea
				placeholder="Hi! Please introduce yourself."
				id={props.id}
				value={props.value}
				onChange={props.onChange}
				className="input-box"
			/>
		</div>
	);
};

export default AboutBox;
