export const languages = [
	{id: 4, name: 'English'},
	{id: 5, name: 'Chinese'},
	{id: 6, name: 'Hindi'},
	{id: 7, name: 'Spanish'},
	{id: 8, name: 'Arabic'},
	{id: 9, name: 'Bengali'},
	{id: 10, name: 'French'},
	{id: 11, name: 'Portuguese'},
	{id: 12, name: 'Urdu'},
	{id: 13, name: 'Indonesian'},
	{id: 14, name: 'Marathi'},
	{id: 15, name: 'Turkish'},
	{id: 16, name: 'Tamil'},
	{id: 17, name: 'Vietnamese'},
	{id: 18, name: 'Iranian'},
	{id: 19, name: 'Swahili'},
	{id: 20, name: 'Italian'},
	{id: 21, name: 'Filipino'},
	{id: 22, name: 'Albanian'},
];
