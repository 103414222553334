/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_CnZn4SnB7",
    "aws_user_pools_web_client_id": "sbar1sk2i7m7hd58srfpbo6de",
    // "oauth": {
    //     "domain": "project-dts-app.auth.us-west-2.amazoncognito.com",
    //     "scope": [
    //         "aws.cognito.signin.user.admin",
    //         "email",
    //         "openid",
    //         "phone",
    //         "profile"
    //     ],
    //     "redirectSignIn": "http://localhost:3000/dev/authCallback",
    //     "redirectSignOut": "http://localhost:3000/dev/authSignOut",
    //     "responseType": "code"
    // },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
