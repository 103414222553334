import React, { useEffect, useState } from 'react';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
//import { useParams } from 'react-router-dom';
//import {v4 as uuidv4} from 'uuid';
import Card from 'react-bootstrap/Card';
//import PostSomething from './PostSomething';
import {
	Link,
} from 'react-router-dom';
//import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
//import Comment from './Comment';
//import Post from './Post';
//import Success from '../../components/Success';

const PostList = (props) => {
	//const urlParams = useParams();
	const [post, setPost] = useState({});
	const [user, setUser] = useState({});
	const [commentCount, setCommentCount] = useState()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		setPost(props.data)
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const params = {
			userId: props.data.user_id,
		}

		apigClient.userUserIdGet(params, null, additionalParams)
			.then((res) => {
				setUser(res.data)
			})
			.catch((err) => {
				console.log(err);
			});

		// Get comment count
		const commentParams = {
			postId: props.data.id,
		}
		apigClient.postsPostIdCommentsGet(commentParams, null, additionalParams)
			.then((res) => {
				setCommentCount(res.data.length)
			})
			.catch((err) => {
				console.log(err);
			});
	}, [props.data]);


	return (
		<Card>
			<Link to={`/home/${post.topic_id}/${post.id}`}>
				<div
					className="discussion-topic"
				>
					{/* <a href="topic/comment"> */}
					<div className="card-title ">
						<div className="parent">
							<div className="card-title-post">
								<img
									className="mobile-user-img-post comment-img "
									id="c-img"
									src={user.image_url ? user.image_url : '/images/Male 2.png'} alt="User Profile Avatar"
									style={{ width: '50px', height: '50px', borderRadius: '100%' }}
								/>
								<div className="card-text-post">
									<div className="text-date">
										<p className="topic-title">
											{post.post_title}
										</p>
										<p className="post-date-desktop comment-and-date">
											{post.created_at}
										</p>
									</div>
									<p className="name-in-topic">
										{user.user_name} in {props.title}{' '}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div id="post-title-text">
						<p id="post-desc">{post.post_content}</p>
					</div>
					<p className="commentCount">
						{' '}
						{commentCount} comments
					</p>
					{/* </a> */}
				</div>
			</Link>
		</Card>
	);
};
export default PostList;
