import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../components/Button';
import Another from './Another';

function HobbiesCheckboxes(props) {
	const [hobbies, setHobbies] = useState([]);
	const [hobbyIds, setHobbyIds] = useState([]);
	const [personalHobbies, setPersonalHobbies] = useState([]);

	const getValue = (hobbyId) => {
		const index = hobbyIds.indexOf(hobbyId);
		if (index > -1) {
			let newArray = hobbyIds.filter((x) => x !== hobbyId);
			setHobbyIds(newArray);
		} else {
			setHobbyIds([...hobbyIds, hobbyId])
		}
	};

	useEffect(() => {
		setHobbies(props.value);
		setHobbyIds(props.userHobbies);
		setPersonalHobbies(props.userPersonalHobbies);
	}, [props.value, props.userHobbies, props.userPersonalHobbies])


	const onSubmit = (e) => {
		e.preventDefault()
		props.handleHobbiesChange(hobbyIds)
		props.handlePersonalHobbiesChange(personalHobbies);
	};

	const handlePersonalHobbiesChange = (hobbies) => {
		setPersonalHobbies(hobbies);
	}

	return (
		<div className="form-wrapper">
			<form onSubmit={onSubmit}>
				{hobbies.map(({ id, hobby_name }, index) => {
					return (
						<FormGroup row className="check-box" key={index}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										value={id}
										onChange={() => getValue(id)}
										checked={hobbyIds.includes(id)}
									/>
								}
								label={hobby_name}
							/>
						</FormGroup>
					);
				})}

				<p className="check-subtitle" id="other-title">
					Other
				</p>

				<Another
					value={personalHobbies}
					propertyName="hobby_name"
					addId="add-hobby"
					handleOtherChange={handlePersonalHobbiesChange}
				/>

				<div className="save-btn">
					<Button
						type="button"
						className="submit"
						onClick={onSubmit}
						value="submit"
						id="save"
						btnText={'Save'}
					/>
				</div>
			</form>
		</div>
	);
}

export default HobbiesCheckboxes;
