import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Button from '../../components/Button';
import FileUpload from './FileUpload';
import { topics } from '../../components/topics';
import './PostSomething.css';
import Name from '../../components/Name';
import ProfileImg from '../../components/ProfileImg';

export default function PostSomething(props) {
	//const [successOpen, setSuccessOpen] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
	let [addedFiles, setFiles] = useState([{}]);

	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const [topic, setTopic] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [file, setfile] = useState('');

	let options = topics.map((topic) => (
		<option key={topic.id}>{topic.topic}</option>
	));

	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	};

	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};

	const handleFileUpload = (e) => {
		if (e.target.files.length) {
			setFiles([...addedFiles, e.target.files[0]]);
			props.handleFileUpload(e.target.files[0])
		}
	};


	const submitHandler = (e) => {
		e.preventDefault();

		props.postSuccessFunc({
			topic: topic,
			title: title,
			description: description
		});
	};

	const [user, setUser] = useState({});

	useEffect(() => {
		setUser(props.user);
		if (props.topic) {
			setTopic(props.topic)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.topic]);


	return (
		<div className="PostSomething">
			<ProfileImg className="comment-img" id="post-img" user={user} />
			<button className="post-something" onClick={toggleModal}>
				<span id="post-something">Post Something</span>
			</button>

			<Modal
				centered
				id="post-modal"
				isOpen={isOpen}
				onRequestClose={toggleModal}
				ariaHideApp={false}
			>
				<div className="create-a-post">
					<p id="modal-title">Create a Post</p>
					<img src="/images/Icon Close.svg" onClick={toggleModal} alt="x" />
				</div>

				<ProfileImg id="user-img" user={user} />

				<div className="name-visibility">
					<Name name="user" id="modal-user" user={user} />
					<div className="post-visibility">
						<img id="lock" src="/images/lock.png" alt="lock" />
						<span id="post-visibility-text">
							Your posts are visible to members only.
						</span>
					</div>
				</div>
				<form>
					{' '}
					<div className="topic">
						<span id="topic">Topic</span>
						<br />

						<div>
							<select
								name="customSearch"
								className="custom-search-select topic-option"
								value={topic}
								onChange={(e) => {
									setTopic(e.target.value);
								}}
							>
								<option>Choose a Topic</option>
								{options}
							</select>
						</div>
					</div>
					<br />
					<div className="title">
						<label id="title">Title </label>
						<br />
						<textarea
							id="title-input"
							type="text"
							name="title"
							placeholder="Write your title..."
							value={title}
							onChange={handleTitleChange}
						/>
					</div>
					<br />
					<textarea
						id="description-input"
						type="text"
						name="description"
						placeholder="Write your post..."
						value={description}
						onChange={handleDescriptionChange}
					/>
					<FileUpload
						files={file}
						handleFileUpload={handleFileUpload}
					/>
					{addedFiles.length > 1 ? (
						<p style={{ marginTop: '0px' }}>{addedFiles[1].name}</p>
					) : null}
					<div className="post-btn">
						<Button
							type="submit"
							id="post-btn"
							btnText={'Post'}
							onClick={(e) => {
								toggleModal(e);
								submitHandler(e);
							}}
						/>
					</div>
				</form>
			</Modal>
		</div>
	);
}
