export const topics = [
	{
		id: 1,
		img: "/images/Introduce Yourself Logo.png",
		topic: "Introduce Yourself",
		topicDesc: "Please tell us your name, where in the world you are connecting from, and what connections you want to make.",
		link: "/Topics/Introduce-Yourself",
	},
	{
		id: 2,
		img: "/images/Survivor Stories Icon.png",
		topic: "Consulting Opportunities",
		topicDesc:
			"Please note there are paid and unpaid consulting opportunities. For more check out our website at https://survivoralliance.org/membership-opportunities.",
		link: "/Topics/Survivor-Stories",
	},
	{
		id: 3,
		img: "/images/Poetry Icon.png",
		topic: "Survivor Alliance Updates",
		topicDesc:
			"Stay in touch and be informed about Survivor Alliance activities all year round.",
		link: "/Topics/Poetry",
	},
	{
		id: 4,
		img: "/images/World News Icon.png",
		topic: "Anti-Trafficking Sector",
		topicDesc:
			"Be informed about the anti-trafficking and anti-slavery sector locally and worldwide.",
		link: "/Topics/World-News",
	},
	{
		id: 5,
		img: "/images/BooksMusicMovies Icon.png",
		topic: "Survivor Leadership Resources",
		topicDesc:
			"Educate yourself with leadership resources and learn the attributes of being a leader.",
		link: "/Topics/Books-Music-and-Movies-for-Survivors",
	},
];
