import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
import CommentList from './CommentList';
import Guidelines from '../Home/Guidelines';
import Card from 'react-bootstrap/Card';
import CommentForm from '../../components/CommentForm';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';



const Comment = (props) => {
	const urlParams = useParams();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const [comments, setComments] = useState([]);
	const [postInfo, setPostInfo] = useState([]);
	const [postFile, setPostFile] = useState({});
	const [user, setUser] = useState([]);
	const [role, setRole] = useState("Member");

	const getFile = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const postParams = {
			postId: urlParams.PSid,
		}

		apigClient.postPostIdFilesGet(postParams, null, additionalParams)
			.then((res) => {
				setPostFile(res.data[0])
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const addComment = async (comment) => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const commentParams = {
			postId: urlParams.PSid,
		}
		const body = {
			comment_content: comment
		}
		apigClient.postPostIdCommentPost(commentParams, body, additionalParams)
			.then((res) => {
				getComments()
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const deleteComment = async (comment) => {
		// TODO: API CALL
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const pathParams = {
			postId: comment.post_id,
			commentId: comment.id
		};
		const deleteCommentBody = {
			status: "2"
		};
		apigClient.postsPostIdCommentCommentIdPost(pathParams, deleteCommentBody, additionalParams)
			.then((res) => {
				console.log(res);
				getComments();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getComments = async () => {
		setLoading(true)
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const commentParams = {
			postId: urlParams.PSid,
		}
		apigClient.postsPostIdCommentsGet(commentParams, null, additionalParams)
			.then((res) => {
				setComments(res.data)
			})
			.catch((err) => {
				console.log(err);
			});

		const topicParams = {
			topicId: urlParams.TPid,
		}
		apigClient.topicsTopicIdPostsGet(topicParams, null, additionalParams)
			.then((res) => {
				var postInfo = res.data.filter((d) => d.id === Number(urlParams.PSid));
				let date = new Date(Number(postInfo[0].created_at) * 1000);
				postInfo[0].created_at = date.toLocaleDateString() + " " + date.toLocaleTimeString('en-US');
				setPostInfo(postInfo[0]);
				const userParams = {
					userId: postInfo[0].user_id,
				}
				apigClient.userUserIdGet(userParams, null, additionalParams)
					.then((res) => {
						setUser(res.data)
						setLoading(false)
					})
					.catch((err) => {
						console.log(err);
					});

			})
			.catch((err) => {
				console.log(err);
				setLoading(false)

			});
	}

	const deletePost = async () => {
		// TODO: API CALL
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const pathParams = {
			topicId: postInfo.topic_id,
			postId: postInfo.id
		};
		const deletePostBody = {
			status: "2"
		};
		apigClient.topicsTopicIdPostPostIdPost(pathParams, deletePostBody, additionalParams)
			.then((res) => {
				console.log(res);
				getComments();
				history.push(`/home/${postInfo.topic_id}/`)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		getComments()
		setRole(props.user.user_role === '1' ? "Admin" : "Member")
		getFile()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div>
			<div className="home">
				<div>
					<div className="row">
						<div className="home-left ">
							<div id="comment-category" className="category">
								<a href="/home" className="category category-1st">
									Topics
								</a>
								&gt;
								<Link
									to={{
										pathname: `/home/${postInfo.topic_id}`,
									}}
									className="category category-2nd"
								>
									{postInfo.topic_id === 1 ? "Introduce Yourself" : null}
									{postInfo.topic_id === 2 ? "Consulting Opportunities" : null}
									{postInfo.topic_id === 3 ? "Survivor Alliance Updates" : null}
									{postInfo.topic_id === 4 ? "Anti-Trafficking Sector" : null}
									{postInfo.topic_id === 5 ? "Survivor Leadership Resources" : null}
								</Link>
								&gt;
								<span className="category category-2nd">
									{postInfo.post_title}
								</span>
							</div>
							<div id="comment-box">
								<Card className="comment-desktop">
									<div id="comment-image">
										<img
											id="comment-img"
											src={
												user.image_url ? user.image_url : '/images/Male 2.png'
											}
											alt="Topic Icon"
											style={{ width: '50px', height: '50px', borderRadius: '100%' }}
										/>
									</div>
									<div id="comment-text">
										<div className="text-date">
											<p id="comment-title">{postInfo.post_title}</p>
											<p className="comment-and-date ">
												{postInfo.created_at}
											</p>
										</div>
										<div id="comment-title-text">
											<p id="name-in-comment">
												{user.user_name} in {postInfo.topic_id === 1 ? "Introduce Yourself" : null}
												{postInfo.topic_id === 2 ? "Consulting Opportunities" : null}
												{postInfo.topic_id === 3 ? "Survivor Alliance Updates" : null}
												{postInfo.topic_id === 4 ? "Anti-Trafficking Sector" : null}
												{postInfo.topic_id === 5 ? "Survivor Leadership Resources" : null}
											</p>
											<p id="desktop-desc">
												{postInfo.post_content}
											</p>
											{postFile ? (
												<a
													href={postFile.file_source}
													target="_blank"
													rel="noreferrer"
													style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none' }}
												>
													<DescriptionIcon alt={postFile.file_name} />
													<p>{postFile.file_name}</p>
												</a>
											) : null}

											{role === "Admin" ?
												<div style={{ display: 'flex' }}>
													<p id="name-in-comment">
														{/* {postToShow.commentCount} */}
														{comments.length} comments
													</p>
													<div tabIndex="0" style={{ display: 'flex', alignItems: 'center', marginLeft: '15px', cursor: 'pointer' }} onClick={deletePost}>
														<DeleteIcon />
														<p>Delete</p>
													</div>
												</div>
												:
												<div>
													<p id="name-in-comment">
														{/* {postToShow.commentCount} */}
														{comments.length} comments
													</p>
												</div>
											}
										</div>
									</div>
								</Card>
								<Card className="comment-mobile">
									<p id="comment-title-mobile">
										{postInfo.post_title}
									</p>

									<div>
										<div id="comment-mobile-flex">
											<img
												className="comment-img "
												src={
													user.image_url ? user.image_url : '/images/Male 2.png'
												}
												alt="Topic Icon"
												style={{ width: '50px', height: '50px', borderRadius: '100%' }}
											/>

											<div id="text-date-mobile">
												<p id="name-in-comment-text">
													{user.user_name} in {postInfo.topic_id === 1 ? "Introduce Yourself" : null}
													{postInfo.topic_id === 2 ? "Survivor Stories" : null}
													{postInfo.topic_id === 3 ? "Poetry" : null}
													{postInfo.topic_id === 4 ? "World News" : null}
													{postInfo.topic_id === 5 ? "Books, Music, and Movies for Survivors" : null}
												</p>
												<p className="comment-date ">
													{' '}
													{postInfo.created_at}
												</p>
											</div>
										</div>

										<div id="comment-text-mobile">
											<p>{postInfo.post_content}</p>
											<p id="name-in-comment">
												{comments.length} comments
											</p>
										</div>
									</div>
								</Card>
								<CommentForm id="add-comment" addComment={addComment} user={props.user} />
								<ul className="ul">
									{comments && comments.map((comm, key) => {
										return <CommentList data={comm} key={key} role={role} deleteComment={deleteComment} />
									})}
								</ul>
							</div>
						</div>
						<div className="home-right">
							<Guidelines className="guideline" />
						</div>
					</div>
				</div>
			</div>
			<Dialog aria-labelledby="simple-dialog-title" open={loading}>
				<DialogTitle id="simple-dialog-title">Loading...		<CircularProgress />
				</DialogTitle>
			</Dialog>
		</div>
	);
};

export default Comment;
