import React from 'react';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';

const DiscussionTopic = (props) => {
	return (
		<div>
			{props.data.map((item, key) => {
				var image = "";
				if (item.title === "Introduce Yourself"){
					image = '/images/Introduce Yourself Logo.png'
				} else if (item.title === "Consulting Opportunities"){
					image = '/images/Survivor Stories Icon.png'
				} else if (item.title === "Survivor Alliance Updates"){
					image = '/images/Poetry Icon.png'
				} else if (item.title === "Anti-Trafficking Sector"){
					image = '/images/World News Icon.png'
				} else if (item.title === "Survivor Leadership Resources"){
					image = '/images/BooksMusicMovies Icon.png'
				} 
				return (
					<Link
						to={{
							pathname: `/home/${item.id}`,
						}}
						key={key}
					>
						<Card className="discussion-topic">
							<Card.Title>
								<img className="comment-img" src={image} alt="Topic Icon" />

								<div className="parent">
									<div className="card-text">
										<p className="topic-title">{item.title}</p>

										<p className="post-date-desktop">
											Most recent post: {item.most_recent_post_date ? item.most_recent_post_date : "No posts yet"}
										</p>
									</div>

									<p className="post-count">{item.post_count} posts</p>
								</div>
							</Card.Title>

							<Card.Text className="post-context">{item.description}</Card.Text>
						</Card>
					</Link>
				);
			})}
		</div>
	);
};

export default DiscussionTopic;
