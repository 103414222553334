export const goals = [
	{id: 1, name: 'To create a group in my locality'},
	{id: 2, name: 'To join to a local group'},
	{id: 3, name: 'To connect with survivors in my community'},
	{id: 4, name: 'To befriend a survivor'},
	{id: 5, name: 'To find a mentor and/or become a mentor'},
	{id: 6, name: 'To widening my networks'},
	{id: 7, name: 'To have my own organisation'},
	{id: 8, name: 'Become a research consultant'},
	{id: 9, name: 'To develop my own research projects'},
	{id: 10, name: 'To go a research degree'},
	{id: 11, name: 'To write a book'},
	{id: 12, name: 'Funding opportunity'},
	{id: 13, name: 'To become an activist in my community'},
	{id: 14, name: 'To become an international activist'},
	{id: 15, name: 'To advice organizations and policies'},
	{id: 16, name: 'To inform laws and policies in the anti trafficking field'},
	{id: 17, name: 'To advocate for survivors inclusion'},
	{id: 18, name: 'To become a trauma informed professional/support worker'},
	{id: 19, name: 'To support organizations in  become a trauma informed'},
	{id: 20, name: 'To train others about trauma informed approach'},
	{id: 21, name: 'To advocate for trauma informed at workplace'},
	{id: 22, name: 'To create a safe house for survivors'},
];
export const CommunityBuildingPeer = [
	{id: 1, name: 'To create a group in my locality'},
	{id: 2, name: 'To join to a local group'},
	{id: 3, name: 'To connect with survivors in my community'},
	{id: 4, name: 'To befriend a survivor'},
	{id: 5, name: 'To find a mentor and/or become a mentor'},
	{id: 6, name: 'To widening my networks '},
	{id: 7, name: 'To have my own organisation'},
];

export const Research = [
	{id: 8, name: 'Become a research consultant'},
	{id: 9, name: 'To develop my own research projects'},
	{id: 10, name: 'To go a research degree'},
	{id: 11, name: 'To write a book'},
	{id: 12, name: 'Funding opportunity'},
];

export const PolicyAdvocacy = [
	{id: 13, name: 'To become an activist in my community'},
	{id: 14, name: 'To become an international activist'},
	{id: 15, name: 'To advice organizations and policies'},
	{id: 16, name: 'To inform laws and policies in the anti trafficking field'},
	{id: 17, name: 'To advocate for survivors inclusion'},
];

export const ProvidingTrauma = [
	{id: 18, name: 'To become a trauma informed professional/support worker'},
	{id: 19, name: 'To support organizations in  become a trauma informed'},
	{id: 20, name: 'To train others about trauma informed approach'},
	{id: 21, name: 'To advocate for trauma informed at workplace'},
	{id: 22, name: 'To create a safe house for survivors'},
];
