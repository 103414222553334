import React, { useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
	//useHistory
} from 'react-router-dom';
import Home from './pages/Home/Home';
import MenuBar from './components/MenuBar/MenuBar';
import MenuBarMobile from './components/MenuBar/MenuBarMobile';
import './App.css';
import CreateYourProfile from './pages/UpdateProfile/CreateYourProfile';
import EditYourProfile from './pages/UpdateProfile/EditYourProfile';
//import NamePlusProfileImage from './components/NamePlusProfileImage';
import Post from './pages/DiscussionForum/Post';
import Comment from './pages/DiscussionForum/Comment';
import AboutMe from './pages/View Profile/AboutMe';
import Success from './components/Success';
import Members from './pages/Members/Members';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Hub } from 'aws-amplify';
import lambdaConfig from "./aws-exports-lambda.js";
import getCurrentUser from "./utils";

function App() {
	const [user, setUser] = React.useState(null);
	const [users, setUsers] = React.useState(null);
	// const [profile, setProfile] = useState({});
	// useEffect(() => {
	// 	setProfile(profile);
	// }, []);
	// const profileHandler = (values) => {
	// 	setProfile(values);
	// 	alert(values.name);
	// 	// <Redirect to = "/about-me"> <AboutMe onData = {profile}/></Redirect>
	// };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		const listener = async (data) => {
			console.log("event", data.payload.event);
			switch (data.payload.event) {
				case "signOut":
					console.log("signOut");
					sessionStorage.setItem("currentUser", "");
					//history.push(`/home`)
					break;
				case "signIn":
					console.log("signIn");
					await getCurrentUser();
				// eslint-disable-next-line no-fallthrough
				default:
					break;
			}
		}
		Hub.listen("auth", listener);

		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};

		apigClient.userGet(null, null, additionalParams)
			.then((res) => {
				setUser(res.data)
			})
			.catch((err) => {
				console.log(err);
			});

		apigClient.usersGet(null, null, additionalParams)
			.then((res) => {
				setUsers(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		user && user.id ?
			<>
				{ users && <MenuBar user={user} users={users} />}
				{ users && <MenuBarMobile user={user} users={users} />}

				<Router>
					<Switch>
						<Route exact path="/">
							<Redirect to="/home" />
						</Route>

						<Route exact path="/home" render={() => <Home user={user} />} />
						<Route exact path="/home/:TPid" render={() => <Post user={user} />} />

						<Route exact path="/home/:TPid/:PSid">
							<Comment user={user} />
						</Route>

						{users && <Route path="/members" render={() => <Members user={user} users={users} />} />}
						<Route path="/profile" render={() => <CreateYourProfile user={user} />}></Route>

						<Route path="/about-me" render={() => <AboutMe user={user} />} />

						<Route exact path="/success" component={Success} />
						<Route exact path="/editProfile" render={() => <EditYourProfile user={user} />} />
					</Switch>
				</Router>
			</>
			:
			<></>
	);
}

export default withAuthenticator(App);
