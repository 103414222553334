import { Auth } from "aws-amplify";
import Cryptr from "cryptr";

export default async function getCurrentUser() {
    const currentUser = sessionStorage.getItem("currentUser");
    if (currentUser) {
        return currentUser;
    }
    try {
        const response = await Auth.currentAuthenticatedUser();
        const { email } = response.attributes;
        const { userPoolId } = response.pool;

        const cryptr = new Cryptr(userPoolId);
        const encryptedEmail = cryptr.encrypt(email);

        sessionStorage.setItem("currentUser", encryptedEmail);

        return encryptedEmail;
    } catch (error) {
        console.log(error);
    }
}