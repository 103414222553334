module.exports = {
    userRoleEnum: {
        MEMBER: "0",
        ADMIN: "1"
    },
    statusEnum: {
        PENDING: "0",
        ACCEPTED: "1",
        REJECTED: "2"
    },
    endpoints: {
        S3_BUCKET: "https://dts-backend-aws-dev-projectdtsbucketdev-1ckbucaotzx7s.s3.us-west-2.amazonaws.com"
    }
};