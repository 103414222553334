const mockdataTopic = [
	{
		id: 1,
		title: 'Introduce Yourself',
		img: '/images/Introduce Yourself Logo.png',
		description:
			'Introduce yourself here. We would love to hear from everyone.',
		post_count: 3,
		most_recent_post_date: '2021-02-02',
		created_at: '2021-02-02',
		updated_at: '2021-02-02',
	},
	{
		id: 2,
		title: 'Survivor Stories',
		img: '/images/Survivor Stories Icon.png',
		description:
			'Share your story and hear about others experiences. This is a safe space.',
		post_count: 3,
		most_recent_post_date: '2021-02-02',
		created_at: '2021-02-02',
		updated_at: '2021-02-02',
	},
	{
		id: 3,
		title: 'Poetry',
		img: '/images/Poetry Icon.png',
		description:
			'Read poems from other survivors or write your own! Poetry recommendations are always welcome too.',
		post_count: 3,
		most_recent_post_date: '2021-02-02',
		created_at: '2021-02-02',
		updated_at: '2021-02-02',
	},
	{
		id: 4,
		title: 'World News',
		img: '/images/World News Icon.png',
		description:
			'What is happening in your country? Join a conversation about news from around the world.',
		post_count: 3,
		most_recent_post_date: '2021-02-02',
		created_at: '2021-02-02',
		updated_at: '2021-02-02',
	},
	{
		id: 5,
		title: 'Books, Music, and Movies for Survivors',
		img: '/images/BooksMusicMovies Icon.png',
		description:
			'Share any books, music, or movies that you think other survivors would benefit from.',
		post_count: 3,
		most_recent_post_date: '2021-02-02',
		created_at: '2021-02-02',
		updated_at: '2021-02-02',
	},
];

export default mockdataTopic;
