import React, {useState} from 'react';

const RequestBadge = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [requested, setRequested] = useState(false);

	return (
		<div>
			{requested ? (
				<p id={props.id} className="badge">
					{props.request_count}{' '}
				</p>
			) : (
				<p id={props.id} className="badge">
					{props.request_count}{' '}
				</p>
			)}
		</div>
	);
};

export default RequestBadge;
