import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../components/Button';
//import {useHistory} from 'react-router-dom';
//import CheckboxTitle from './CheckboxTitle';
//import Modal from 'react-modal';
import Another from './Another';

function InterestCheckboxes(props) {
	const [interests, setInterests] = useState([]);
	const [interestIds, setInterestIds] = useState([]);
	const [personalInterests, setPersonalInterests] = useState([]);

	const getValue = (interestId) => {
		const index = interestIds.indexOf(interestId);
		if (index > -1) {
			let newArray = interestIds.filter((x) => x !== interestId);
			setInterestIds(newArray);
		} else {
			setInterestIds([...interestIds, interestId]);
		}
	};

	useEffect(() => {
		setInterests(props.value);
		setInterestIds(props.userInterests);
		setPersonalInterests(props.userPersonalInterests);
	}, [props.value, props.userInterests, props.userPersonalInterests])

	const onSubmit = (e) => {
		e.preventDefault()
		props.handleInterestsChange(interestIds)
		props.handlePersonalInterestsChange(personalInterests);
	};

	const handlePersonalInterestsChange = (interests) => {
		setPersonalInterests(interests);
	}

	return (
		<div>
			<section className="form-wrapper">
				<form onSubmit={onSubmit}>
					{interests.map(({ id, interest_name }, index) => {
						return (
							<FormGroup row className="check-box" key={index}>
								<FormControlLabel
									control={
										<Checkbox
											color="primary"
											value={id}
											onChange={() => getValue(id)}
											checked={interestIds.includes(id)}
										/>
									}
									label={interest_name}
								/>
							</FormGroup>
						);
					})}
					<p className="check-subtitle" id="other-title">
						Other
					</p>

					<Another
						addId="add-hobby"
						value={personalInterests}
						propertyName="interest_name"
						handleOtherChange={handlePersonalInterestsChange}
					/>
					<div className="save-btn">
						<Button
							type="submit"
							className="submit"
							onClick={onSubmit}
							value="submit"
							id="save"
							btnText={'Save'}
						/>
					</div>
				</form>
			</section>
		</div>
	);
}

export default InterestCheckboxes;
