import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../components/Button';
//import {useHistory} from 'react-router-dom';
//import InputBox from '../../../components/InputBox';
// import {
// 	CommunityBuildingPeer,
// 	Research,
// 	PolicyAdvocacy,
// 	ProvidingTrauma,
// } from '../../../components/ProfileData/GoalsData';
//import CheckboxTitle from './CheckboxTitle';
import Another from './Another';

function GoalsCheckboxes(props) {
	const [goals, setGoals] = useState([]);
	const [goalIds, setGoalIds] = useState([]);
	const [personalGoals, setPersonalGoals] = useState([]);

	const getValue = (goalId) => {
		const index = goalIds.indexOf(goalId);
		if (index > -1) {
			let newArray = goalIds.filter((x) => x !== goalId);
			setGoalIds(newArray);
		} else {
			setGoalIds([...goalIds, goalId])
		}
	};

	useEffect(() => {
		setGoals(props.value);
		setGoalIds(props.userGoals);
		setPersonalGoals(props.userPersonalGoals);
	}, [props.value, props.userGoals, props.userPersonalGoals])


	const CommunityBuildingPeer = goals.slice(0, 7);
	const Research = goals.slice(7, 12);
	const PolicyAdvocacy = goals.slice(12, 17);
	const ProvidingTrauma = goals.slice(17, 21);

	const onSubmit = (e) => {
		e.preventDefault()
		props.handleGoalsChange(goalIds)
		props.handlePersonalGoalsChange(personalGoals);
	};

	const handlePersonalGoalsChange = (goals) => {
		setPersonalGoals(goals);
	}

	return (
		<div className="form-wrapper">
			<form onSubmit={onSubmit}>
				<br />
				<p className="check-subtitle">Community Building and Peer Mentoring</p>
				{CommunityBuildingPeer.map(({ id, goal_name }, index) => {
					return (
						<FormGroup row className="check-box" key={index}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										value={id}
										onChange={() => getValue(id)}
										checked={goalIds.includes(id)}
									/>
								}
								label={goal_name}
							/>
						</FormGroup>
					);
				})}

				<p className="check-subtitle">Policy Advocacy and Community Organizing</p>
				{PolicyAdvocacy.map(({ id, goal_name }, index) => {
					return (
						<FormGroup row className="check-box" key={index}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										value={id}
										onChange={() => getValue(id)}
										checked={goalIds.includes(id)}
									/>
								}
								label={goal_name}
							/>
						</FormGroup>
					);
				})}

				<p className="check-subtitle">
					Trauma Informed Direct Practice
				</p>
				{ProvidingTrauma.map(({ id, goal_name }, index) => {
					return (
						<FormGroup row className="check-box" key={index}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										value={id}
										onChange={() => getValue(id)}
										checked={goalIds.includes(id)}
									/>
								}
								label={goal_name}
							/>
						</FormGroup>
					);
				})}

				<p className="check-subtitle">
					Research and Research Practice
				</p>
				{Research.map(({ id, goal_name }, index) => {
					return (
						<FormGroup row className="check-box" key={index}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										value={id}
										onChange={() => getValue(id)}
										checked={goalIds.includes(id)}
									/>
								}
								label={goal_name}
							/>
						</FormGroup>
					);
				})}

				{/* <div className="check-subtitle">
					<InputBox title="Other" id={"check-subtitle"} />
				</div> */}

				<p className="check-subtitle" id="other-title">
					Other
				</p>

				<Another
					value={personalGoals}
					propertyName="goal_name"
					addId="add-hobby"
					handleOtherChange={handlePersonalGoalsChange}
				/>

				<div className="save-btn">
					<Button
						type="button"
						className="submit"
						onClick={onSubmit}
						value="submit"
						id="save"
						btnText={'Save'}
					/>
				</div>
			</form>
		</div>
	);
}

export default GoalsCheckboxes;
