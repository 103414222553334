// import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";

const InputBox = (props) => {
	return (
		<div>
			<p className="input-title">{props.title}</p>
			<input
				id={props.id}
				title={props.title}
				value={props.value}
				onChange={props.onChange}
				className="input-box"
			/>
		</div>
	);
};

export default InputBox;
