export const hobbies = [
	{id: 1, name: 'Singing'},
	{id: 2, name: 'Reading'},
	{id: 3, name: 'Sewing'},
	{id: 4, name: 'Painting'},
	{id: 5, name: 'Drawing '},
	{id: 6, name: 'Cooking'},
	{id: 7, name: 'Writing'},
	{id: 8, name: 'Fishing'},
	{id: 9, name: 'Hiking'},
	{id: 10, name: 'Yoga'},
	{id: 11, name: 'Knitting'},
	{id: 12, name: 'Swimming'},
	{id: 13, name: 'Photography '},
	{id: 14, name: 'Playing instruments'},
	{id: 15, name: 'Cinema'},
	{id: 16, name: 'Outdoor activities'},
	{id: 17, name: 'Crafts'},
];
