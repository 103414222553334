/* eslint-disable array-callback-return */
import React from 'react';
import './AboutMeDesktop.css';
import Button from '../../components/Button';
import { AmplifySignOut } from '@aws-amplify/ui-react'
import DescriptionIcon from '@material-ui/icons/Description';

const AboutMeDesktop = (props) => {

	return (
		<div className="about-desktop-page">
			<div className="about-me-wrapper-desktop">
				<div className="left-aboutme">
					<div className="profile-img-name">
						<img
							src={props.me.image_url ? props.me.image_url : '/images/Male 2.png'}
							id={props.id}
							className={props.className}
							alt="profile-img"
						/>
						<p id="name-NamePlusProfileImage">{props.me.user_name}</p>
					</div>
					<form>
						<div className="about-me border">
							<p className="subtitle about-desc">About Me</p>

							<table>
								<tr>
									<th className="about-desc" style={{ textAlign: 'left' }}>Occupation</th>
								</tr>
								<tr>
									<td>{props.me.occupation}</td>
								</tr>
							</table>
							<table>
								<tr style={{ marginTop: '5px' }}>
									<th className="about-desc" style={{ textAlign: 'left' }}>County</th>
									<th className="about-desc" style={{ textAlign: 'left' }}>State/Region</th>
								</tr>
								<tr>
									<td>{props.me.country}</td>
									<td>{props.me.state}</td>
								</tr>
							</table>
							<p className="about-desc bold mgbtm0 ">Language(s)</p>
							<div className="list" >
								{props.languages.map((language, langIndex) => {
									if (props.me.language && props.me.language.includes(language.id)) {
										return (
											<p
												className=" about-me-box about-me-Language "
												key={langIndex}
											>{language.language_name}</p>

										);
									}
								})}
							</div>

							<p className="about-desc bold mgbtm0">Goal(s)</p>
							<div className="list-goals">
								{props.goals.map((goal, goalIndex) => {
									if (props.me.goal && props.me.goal.includes(goal.id)) {
										return (
											<p
												className=" about-me-box about-me-Language "
												key={goalIndex}
											>
												{goal.goal_name}
											</p>
										);
									}
								})}
								{props.me && props.me.goal_other && props.me.goal_other.map((goal, id) => {
									return (
										<p
											className=" about-me-box about-me-Language "
											key={id}
										>
											{goal.goal_name}
										</p>
									);
								})}
							</div>
							<p className="about-desc bold mgbtm0 ">Interest(s)</p>
							<div className="list">
								{props.interests.map((interest, interestIndex) => {
									if (props.me.interest && props.me.interest.includes(interest.id)) {
										return (
											<p
												className=" about-me-box about-me-Language "
												key={interestIndex}
											>
												{interest.interest_name}
											</p>
										);
									}
								})}
								{props.me && props.me.interest_other && props.me.interest_other.map((interest, id) => {
									return (
										<p
											className=" about-me-box about-me-Language "
											key={id}
										>
											{interest.interest_name}
										</p>
									);
								})}
							</div>

							<p className="about-desc bold mgbtm0 ">Hobby(ies)</p>
							<div className="list">
								{props.hobbies.map((hobby, hobbyIndex) => {
									if (props.me.hobby && props.me.hobby.includes(hobby.id)) {
										return (
											<p
												className=" about-me-box about-me-Language "
												key={hobbyIndex}
											>
												{hobby.hobby_name}
											</p>
										);
									}
								})}
								{props.me && props.me.hobby_other && props.me.hobby_other.map((hobby, id) => {
									return (
										<p
											className=" about-me-box about-me-Language "
											key={id}
										>
											{hobby.hobby_name}
										</p>
									);
								})}
							</div>
							<br />
							{props.files ? (
								<div>
									<p className="about-desc bold mgbtm0 ">Files</p>
									<br />
									<div className="list">
										{props.files.map((file, fileIndex) => {
											return (
												<a
													className=" about-me-box about-me-Language "
													key={fileIndex}
													href={file.file_source}
													target="_blank"
													rel="noreferrer"
													style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none' }}
												>
													<DescriptionIcon alt={file.file_name} />
													<p>{file.file_name}</p>
												</a>
											);

										})}
									</div>
								</div>
							) : null}
						</div>
					</form>
				</div>

				<div className="right-aboutme">
					<form>
						<div className="about border ">
							<p className="subtitle about-desc">About</p>
							<p className="about-desc">{props.me.about}</p>
						</div>

						<div className="about-desc border cntc-desktop">
							<div className="contact-info ">
								<p className="subtitle ">Contact Information</p>
								<div>
									{props.me.email ? (
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<p>Email:</p>
											<p>{props.me.email}</p>
										</div>) : null}
									{props.me.whatsapp ? (
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<p>WhatsApp:</p>
											<p>{props.me.whatsapp}</p>
										</div>) : null}
									{props.me.twitter ? (
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<p>Twitter:</p>
											<p>{props.me.twitter}</p>
										</div>) : null}
									{props.me.facebook ? (
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<p>Facebook:</p>
											<p>{props.me.facebook}</p>
										</div>) : null}
									{props.me.linkedin ? (
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<p>LinkedIn:</p>
											<p>{props.me.linkedin}</p>
										</div>) : null}
									{props.me.instagram ? (
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<p>Instagram:</p>
											<p>{props.me.instagram}</p>
										</div>) : null}
									{props.me.contact.map((contact, key) => {
										return (
											<div key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
												<p>Other:</p>
												<p>{contact.contact_info}</p>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</form>
					<div className="guidelines-btn">
						<a href="/editProfile">
							<Button
								type="submit"
								id="guidelines-btn"
								btnText={'Edit your profile'}
							/>
						</a>
					</div>
					<div className="guidelines-btn amplify-button">
						<AmplifySignOut></AmplifySignOut>

						{/* <a href="/editProfile">
							<Button
								type="submit"
								id="guidelines-btn"
								btnText={'Sign Out'}
							/>
						</a> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutMeDesktop;
