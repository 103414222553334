import React, { useState } from 'react';

const CommentForm = (props) => {
	const [comment, setComment] = useState('');
	// let userImage = '/images/Male 2.png';
	// let name = 'userName';

	const handleSubmit = (e) => {
		e.preventDefault();
		props.addComment(comment)
		setComment('');
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="comment-input">
				<img className="comment-img" src={
					props.user.image_url ? props.user.image_url : '/images/Male 2.png'
				} alt="User Avatar" style={{ width: '50px', height: '50px', borderRadius: '100%' }} />
				<input
					id="write-a-comment"
					type="text"
					placeholder="Write a comment..."
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>

				<button id="comment-btn" type="submit">
					Comment
				</button>
			</div>
		</form>
	);
};

export default CommentForm;
