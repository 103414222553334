import { useEffect, useState, useRef } from 'react';

const Another = (props) => {
	const [inputList, setInputList] = useState([]);
	const _isMounted = useRef(false);

	const newValues = props.value.map(value => {
		value.another = value[props.propertyName] || value.another;
		delete value[props.propertyName];
		return value;
	});

	useEffect(() => {
		_isMounted.current = true;
		setInputList([...newValues, { another: "" }])

		return () => {
			_isMounted.current = false;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_isMounted.current]);

	const handleAnotherChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		list[index][name] = value
		setInputList(list);
		props.handleOtherChange(inputList);
	};

	const handleRemoveClick = (e, index) => {
		e.preventDefault();
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
		props.handleOtherChange(list);
	};

	const handleAddClick = (e) => {
		e.preventDefault();
		props.handleOtherChange(inputList);
		setInputList([...inputList, { another: "" }]);
	};

	return (
		<div className="another">
			{inputList.map((x, i) => {
				return (
					<div className="another-box" id={props.addId}>
						<input
							name="another"
							className="input-box"
							value={x.another}
							onChange={(e) => handleAnotherChange(e, i)}
						/>

						<div className="btn-box">
							{
								inputList.length !== 1 && (
									<button
										className="another-btn"
										onClick={(e) => handleRemoveClick(e, i)}
									>
										Remove
									</button>
								)
							}
							{inputList.length - 1 === i && (
								<button className="another-btn" onClick={handleAddClick}>
									Add
								</button>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Another;
