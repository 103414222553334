/* eslint-disable array-callback-return */

// *********
import { useState } from 'react';
import Success from '../../components/Success';
// *********

import React, { useEffect } from 'react';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
import ContactInfo from '../Connection/ContactInfo';
import './MemberAboutDesktop.css';

const MemberAboutMeDesktop = (props) => {
	const [userFiles, setUserFiles] = React.useState([])


	const getFiles = async (user) => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const additionalParams = {
			queryParams: {
				user: await getCurrentUser()
			}
		};
		const params = {
			userId: user.id
		}
		apigClient.userUserIdFilesGet(params, {}, additionalParams)
			.then((res) => {
				setUserFiles(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getMembers = () => {
		props.getMembers()
	}

	// **************
	const [successOpen, setSuccessOpen] = useState(false);

	const postSuccessFunc = () => {
		setSuccessOpen(true);
	};

	const closeSuccess = () => {
		setSuccessOpen(false);
		props.toggleModal()
	};


	// ***************

	useEffect(() => {
		getFiles(props.me)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="about-desktop-page">
			<img
				className="about-x"
				src="/images/Icon Close.svg"
				onRequestClose={props.toggleModal}
				onClick={props.toggleModal}
				alt="x"
			/>
			<div className="profile-img-name">
				<img
					src={props.me.image_url ? props.me.image_url : '/images/Male 2.png'}
					// className={props.className}
					alt="profile-img"
				/>
				<p id="name-NamePlusProfileImage">{props.me.user_name}</p>
			</div>
			<div className="about-me-wrapper-desktop">
				<div className="left-member">
					<form>
						<div className="about-me border">
							<p className="subtitle about-desc">About Me</p>

							<table>
								<tr>
									<th className="about-desc">Occupation</th>
								</tr>
								<tr>
									<td>{props.me.occupation}</td>
								</tr>
							</table>
							<table>
								<tr>
									<th className="about-desc" style={{ textAlign: 'left' }}>Country</th>
									<th className="about-desc">State/Region</th>
								</tr>
								<tr>
									<td>{props.me.country}</td>
									<td>{props.me.state}</td>
								</tr>
							</table>
							<p className="about-desc bold mgbtm0 ">Language(s)</p>
							{props.languages.map((language, langIndex) => {
								if (props.me.language && props.me.language.includes(language.id)) {
									return (
										<p
											className=" about-me-box about-me-Language "
											key={langIndex}
										>{language.language_name}</p>

									);
								}
							})}
							<p className="about-desc bold mgbtm0">Goal(s)</p>
							{props.goals.map((goal, goalIndex) => {
								if (props.me.goal && props.me.goal.includes(goal.id)) {
									return (
										<p
											className=" about-me-box about-me-Language "
											key={goalIndex}
										>
											{goal.goal_name}
										</p>
									);
								}
							})}
							{props.me && props.me.goal_other && props.me.goal_other.map((goal, id) => {
								return (
									<p
										className=" about-me-box about-me-Language "
										key={id}
									>
										{goal.goal_name}
									</p>
								);
							})}

							<p className="about-desc bold mgbtm0 ">Interest(s)</p>
							{props.interests.map((interest, interestIndex) => {
								if (props.me.interest && props.me.interest.includes(interest.id)) {
									return (
										<p
											className=" about-me-box about-me-Language "
											key={interestIndex}
										>
											{interest.interest_name}
										</p>
									);
								}
							})}
							{props.me && props.me.interest_other && props.me.interest_other.map((interest, id) => {
								return (
									<p
										className=" about-me-box about-me-Language "
										key={id}
									>
										{interest.interest_name}
									</p>
								);
							})}
							<p className="about-desc bold mgbtm0 ">Hobby(ies)</p>
							{props.hobbies.map((hobby, hobbyIndex) => {
								if (props.me.hobby && props.me.hobby.includes(hobby.id)) {
									return (
										<p
											className=" about-me-box about-me-Language "
											key={hobbyIndex}
										>
											{hobby.hobby_name}
										</p>
									);
								}
							})}
							{props.me && props.me.hobby_other && props.me.hobby_other.map((hobby, id) => {
								return (
									<p
										className=" about-me-box about-me-Language "
										key={id}
									>
										{hobby.hobby_name}
									</p>
								);
							})}

							{
								userFiles.length > 0 ?
									<div className="file-list-container about-desc">
										<p className="input-title">Files</p>
										{userFiles.map((file, id) => {
											return (
												<a target="_blank" without rel="noreferrer" href={file.file_source} key={id}>
													<span>{file.file_name}</span>
												</a>
											);
										})}
									</div> :
									<div></div>
							}
							<br />
						</div>
					</form>
				</div>

				<div className="right-member">
					<form>
						<div className="about border ">
							<p className="subtitle about-desc">About</p>
							<p className="about-desc">{props.me.about}</p>
						</div>

						<div className="subtitle about-desc border cntc-desktop">
							<ContactInfo member={props.me} key={props.me.id} getMembers={getMembers}
								// *********
								postSuccessFunc={postSuccessFunc}
							// *********

							/>
						</div>
					</form>
				</div>
				<Success open={successOpen} handleClose={closeSuccess} SuccessMsg={'Success! Your connection request has been submitted.'}

				/>
			</div>
		</div>
	);
};

export default MemberAboutMeDesktop;
