import React, { useState } from 'react';


const ProfileImg = (props) => {
	// const [img, setImg] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [signed, setSigned] = useState();
	

	return (
		<div className="user-pic-container">
			{signed ? (
				<img
					src={props.user.image_url ? props.user.image_url : "/images/Male 2.png"}
					id={props.id}
					className={props.className}
					alt="Profile Avatar"
					style={{width:'50px', height: '50px', borderRadius:'100%'}}
				/>
			) : (
				<img
					src={props.user.image_url ? props.user.image_url : "/images/Male 2.png"}
					id={props.id}
					className={props.className}
					alt="Profile Avatar"
					style={{width:'50px', height: '50px'}}
				/>
			)}
		</div>
	);
};

export default ProfileImg;
