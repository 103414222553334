import React from "react";

export default function AddYourPicture(props) {
  // const [image, setImage] = useState({ preview: "", raw: "" });

  /*
  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  */

  // const handleUpload = async e => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("image", image.raw);

  // await fetch("YOUR_URL", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "multipart/form-data"
  //   },
  //   body: formData
  // });
  // };

  return (
    <div>
      <label htmlFor="upload-button">
        <div className="profile-img-name">
          {props.image ? (
            <img
              src={props.image}
              alt="dummy"
            />
          ) : (
              <div className="add-Picture">
                <img
                  id="add-avatar"
                  src="/images/Add Avatar.png"
                  alt="attach"
                />
              </div>
            )}
          <p id="addPicture-text">{props.text}</p>
        </div>
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={props.handleChange}
      />

    </div>
  );
}

