import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../components/Button';

function LangCheckboxes(props) {
	// eslint-disable-next-line no-unused-vars
	const [lang, setLang] = useState([]);
	const [langIds, setLangIds] = useState([]);

	const getValue = (languageId) => {
		const index = langIds.indexOf(languageId);
		if (index > -1) {
			let newArray = langIds.filter((x) => x !== languageId);
			setLangIds(newArray);
		} else {
			setLangIds([...langIds, languageId])
		}
	};

	useEffect(() => {
		setLang(props.value)
		setLangIds(props.userLanguages)
	}, [props.value, props.userLanguages])

	const onSubmit = (e) => {
		e.preventDefault()
		props.handleLanguageChange(langIds)
	};

	return (
		<div>
			<section className="form-wrapper">
				<form onSubmit={onSubmit}>
					{props.value.map((language, index) => {
						return (
							<FormGroup row className="check-box" key={index}>
								<FormControlLabel
									control={
										<Checkbox
											color="primary"
											value={language.id}
											onChange={() => getValue(language.id)}
											checked={langIds.includes(language.id)}
										/>
									}
									label={language.language_name}
								/>
							</FormGroup>
						);
					})}
					<div className="save-btn">
						<Button
							type="submit"
							className="submit"
							onClick={onSubmit}
							value="submit"
							id="save"
							btnText={'Save'}
						/>
					</div>
				</form>
			</section>
		</div>
	);
}

export default LangCheckboxes;
