import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import Button from '../../components/Button';
import InputPullDown from '../../components/InputPullDown';
import {countries} from '../../components/Countries';
import {languages} from '../../components/ProfileData/LanguageData';
import {goals} from '../../components/ProfileData/GoalsData';
import {interests} from '../../components/ProfileData/InterestsData';
import {hobbies} from '../../components/ProfileData/HobbyData';

export default function Filter(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [filters, setFilters] = useState({
		country: '',
		goals: '',
		languages: '',
		interests: '',
		hobbies: '',
	});

	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const countryChangeHandler = (e) => {
		setFilters((prevState) => {
			return {...prevState, country: e.target.value};
		});
	};
	const goalChangeHandler = (e) => {
		setFilters((prevState) => {
			return {...prevState, goals: e.target.value};
		});
	};
	const languageChangeHandler = (e) => {
		setFilters((prevState) => {
			return {...prevState, languages: e.target.value};
		});
	};

	const hobbyChangeHandler = (e) => {
		setFilters((prevState) => {
			return {...prevState, hobbies: e.target.value};
		});
	};

	const interestChangeHandler = (e) => {
		setFilters((prevState) => {
			return {...prevState, interests: e.target.value};
		});
	};

	const filterHandler = (e) => {
		e.preventDefault();
		props.toggle(true); //setIsFiltered => true
		props.fn(filters);
		// console.log(filters);
	};

	useEffect(() => {
		setFilters(props.filters)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="">
			<Button
				type="submit"
				id="filter-btn"
				btnText={'Filter'}
				onClick={toggleModal}
			/>
			<Modal
				id="post-modal"
				isOpen={isOpen}
				onRequestClose={toggleModal}
				ariaHideApp={false}
			>
				<section className="form-wrapper">
					<div className="create-a-post">
						<p id="modal-title">Filter Members</p>
						<img src="/images/Icon Close.svg" onClick={toggleModal} alt="x" />
					</div>

					<form>
						{' '}
						<InputPullDown
							data={countries}
							value={filters.country}
							onChange={countryChangeHandler}
							text={'Countries'}
							option={'Any'}
							countries={true}
						/>
						<InputPullDown
							data={languages}
							value={filters.languages}
							onChange={languageChangeHandler}
							text={'Languages'}
							option={'Any'}
						/>
						<InputPullDown
							data={goals}
							value={filters.goals}
							onChange={goalChangeHandler}
							text={'Goals'}
							option={'Any'}
						/>
						<InputPullDown
							data={interests}
							value={filters.interests}
							onChange={interestChangeHandler}
							text={'Interests'}
							option={'Any'}
						/>
						<InputPullDown
							data={hobbies}
							value={filters.hobbies}
							onChange={hobbyChangeHandler}
							text={'Hobbies'}
							option={'Any'}
						/>
						<br />
						<Button
							type="submit"
							className="submit"
							value="submit"
							id="save"
							btnText={'Apply Filter'}
							// href="members/filtered-members"
							onClick={filterHandler}
						/>
					</form>
				</section>
			</Modal>
		</div>
	);
}
