import React, {useState} from 'react';

const Name = (props) => {
	//const [name, setName] = useState();
	// eslint-disable-next-line no-unused-vars
	const [signed, setSigned] = useState();

	// function getName() {
	// 	setName;
	// }

	// useEffect(() => {
	// 	getName;
	// }, []);
	console.log(props)

	return (
		<div class="user-name-container">
			{signed ? (
				<p className={props.className}>{props.user.user_name ? props.user.user_name : "You"}</p>
			) : (
				<p className="name" id={props.id}>
					{props.user.user_name ? props.user.user_name : "You"}
				</p>
			)}
		</div>
	);
};

export default Name;
