/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
import MembersList from './MembersList';
import YourConnection from '../Connection/YourConnection';
import ConnectionRequests from '../Connection/ConnectionRequests';
import Filter from './Filter';
import EditFilter from './EditFilter';
import Modal from 'react-modal';
import RequestBadge from '../Connection/RequestBadge';
// import {countries} from '../../components/Countries'
// import { Auth } from "aws-amplify";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

const Members = (props) => {
	const [currentUser, setCurrentUser] = useState({});
	const [members, setMembers] = useState([{ languages: [], goals: [], interests: [], hobbies: [] }]);
	const [languages, setLanguages] = React.useState([])
	const [goals, setGoals] = React.useState([])
	const [interests, setInterests] = React.useState([])
	const [hobbies, setHobbies] = React.useState([])
	const [loading, setLoading] = React.useState(false);

	const handleClose = () => {
		setLoading(false)
	}

	async function fetchData() {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUserEmail = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUserEmail
			}
		};
		await apigClient.usersGet(null, null, additionalParams)
			.then((res) => {
				setMembers(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getMembers = () => {
		fetchData()
	}

	useEffect(() => {
		setMembers(props.users)
		setCurrentUser(props.user)
		getLanguages()
		getGoals()
		getInterests()
		getHobbies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isConnectionOpen, setIsConnectionOpen] = useState(false);
	const [isConnectRequestOpen, setIsConnectRequestOpen] = useState(false);

	const [isEditOpen, setIsEditOpen] = useState(false);

	const [isFiltered, setIsFiltered] = useState(false);

	function resetFilter() {
		setIsFiltered(false);
		setFil({
			country: "",
			goals: "",
			languages: "",
			interests: "",
			hobbies: ""
		});
	}

	function toggleConnection() {
		setIsConnectionOpen(!isConnectionOpen);
	}
	function toggleConnectRequest() {
		setIsConnectRequestOpen(!isConnectRequestOpen);
	}

	function toggleEdit() {
		setIsEditOpen(!isEditOpen);
	}

	const getUser = async (id) => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);

		const additionalParams = {
			queryParams: {
				user: await getCurrentUser()
			}
		};

		const params = {
			userId: id
		};

		const body = {};

		const res = await apigClient.userUserIdGet(params, body, additionalParams);
		return res.data;
	}

	const getLanguages = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		await apigClient.languagesGet(null, null, additionalParams)
			.then((res) => {
				setLanguages(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getGoals = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		await apigClient.goalsGet(null, null, additionalParams)
			.then((res) => {
				setGoals(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getInterests = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		await apigClient.interestsGet(null, null, additionalParams)
			.then((res) => {
				setInterests(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getHobbies = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		await apigClient.hobbiesGet(null, null, additionalParams)
			.then((res) => {
				setHobbies(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const updateConnection = async (id, status) => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);

		const additionalParams = {
			queryParams: {
				user: await getCurrentUser()
			}
		};

		const params = {
			connectionId: id
		};

		const body = {
			status
		};

		try {
			const res = await apigClient.connectionsConnectionIdPost(params, body, additionalParams);
			if (res) {
				console.log(res);
				const newUser = await getUser(id);
				console.log("newUser", newUser);
				const updatedMembers = members.map(member => {
					if (member.id === id) {
						member = newUser;
					}
					return member;
				});
				console.log(updatedMembers);
				setMembers(updatedMembers);
			}
		} catch (err) {
			// TODO: handle error
			console.log(err);
		}
	}

	const [filteredMemberMockData, setFilteredMemberMockData] = useState([]);
	const [fil, setFil] = useState({
		country: '',
		goals: '',
		languages: '',
		interests: '',
		hobbies: '',
	});
	const [filText, setFilText] = useState({
		country: '',
		goals: '',
		languages: '',
		interests: '',
		hobbies: '',
	});

	const filtering = (filters) => {
		setFilteredMemberMockData(
			members.filter(
				(d) =>
					(filters.country === '' || d.country === filters.country) &&
					(filters.languages === '' ||
						d.language.includes(+filters.languages)) &&
					(filters.goals === '' || d.goal.includes(+filters.goals)) &&
					(filters.interests === '' ||
						d.interest.includes(+filters.interests)) &&
					(filters.hobbies === '' ||
						d.hobby.includes(+filters.hobbies))
			),
		);
		var languageText = filters.languages;
		for (let i = 0; i < languages.length; i++) {
			if (languages[i].id === +filters.languages) {
				languageText = languages[i].language_name
			}
		}
		var goalText = filters.goals;
		for (let i = 0; i < goals.length; i++) {
			if (goals[i].id === +filters.goals) {
				goalText = goals[i].goal_name
			}
		}
		var interestText = filters.interests;
		for (let i = 0; i < interests.length; i++) {
			if (interests[i].id === +filters.interests) {
				interestText = interests[i].interest_name
			}
		}
		var hobbyText = filters.hobbies;
		for (let i = 0; i < hobbies.length; i++) {
			if (hobbies[i].id === +filters.hobbies) {
				hobbyText = hobbies[i].hobby_name
			}
		}

		setFilText({
			country: filters.country,
			goals: goalText,
			languages: languageText,
			interests: interestText,
			hobbies: hobbyText,
		})

		setFil(filters);
	};

	return (
		<div>
			<div className="home">
				<div>
					<div className="row">
						<div className="home-left">
							<p id="discussion-title">
								Connect with Other Survivor Alliance Members
							</p>
							<div className="flex">
								<img
									className="member-btn"
									src="/images/Group 2.svg"
									onClick={toggleConnection}
									alt="Profile Avatar"
								/>
								<RequestBadge id="mobil-badge" />

								<Modal
									ariaHideApp={false}
									id="members-modal"
									isOpen={isConnectionOpen}
									onRequestClose={toggleConnection}
								>
									<YourConnection
										className="modal-box "
										toggleModal={toggleConnection}
										members={members}
										currentUser={currentUser}
										languages={languages}
										goals={goals}
										interests={interests}
										hobbies={hobbies}
									/>
								</Modal>
								<img
									className="member-btn"
									src="/images/Group 3.svg"
									onClick={toggleConnectRequest}
									alt="Profile Avatar"
								/>
								<RequestBadge id="mobil-badge" />

								<Modal
									ariaHideApp={false}
									id="members-modal2"
									isOpen={isConnectRequestOpen}
									onRequestClose={toggleConnectRequest}
								>
									<ConnectionRequests
										className="modal-box "
										members={members}
										toggleModal={toggleConnectRequest}
										currentUser={currentUser}
										updateConnection={updateConnection}
										languages={languages}
										goals={goals}
										interests={interests}
										hobbies={hobbies}
									/>
								</Modal>
							</div>
							{isFiltered === false ? (
								<div>
									<Filter toggle={setIsFiltered} fn={filtering} filters={fil} />
									<MembersList
										data={members.filter(member => member.id !== currentUser.id)} languages={languages} goals={goals}
										interests={interests}
										hobbies={hobbies}
										getMembers={getMembers} />
								</div>
							) : (
									<div id="mg-side">
										<div className="flex">
											<p>
												<b>Filters applied</b>:{' '}
											</p>
											{filText.languages !== '' ? (
												<div id="lang"> Language: {filText.languages}</div>
											) : null}

											{filText.country !== '' ? (
												<div id="lang"> Country: {filText.country}</div>
											) : null}

											{filText.goals !== '' ? (
												<div id="lang"> Goal: {filText.goals}</div>
											) : null}

											{filText.interests !== '' ? (
												<div id="lang"> Interest: {filText.interests}</div>
											) : null}

											{filText.hobbies !== '' ? (
												<div id="lang"> Hobby: {filText.hobbies}</div>
											) : null}
										</div>

										<div className="flex space-between">
											<p onClick={toggleEdit}>
												<u>Edit filter </u>
											</p>
											<p onClick={resetFilter}>
												<u>Clear filters</u>
											</p>
										</div>
										<Modal
											ariaHideApp={false}
											id="post-modal"
											isOpen={isEditOpen}
											onRequestClose={toggleEdit}
										>
											<EditFilter
												toggle={setIsFiltered}
												fn={filtering}
												toggleModal={toggleEdit}
												filters={fil}
											/>
										</Modal>
										<MembersList data={filteredMemberMockData} languages={languages} goals={goals} interests={interests} hobbies={hobbies} getMembers={getMembers} />
									</div>
								)}
						</div>
						<div className="home-right-members">
							<ConnectionRequests
								members={members}
								currentUser={currentUser}
								updateConnection={updateConnection}
							/>
							<YourConnection
								members={members}
								currentUser={currentUser}
								className="guideline"
								languages={languages} goals={goals} interests={interests} hobbies={hobbies}
							/>
						</div>
					</div>
				</div>
			</div>
			<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={loading}>
			<DialogTitle id="simple-dialog-title">Loading...		<CircularProgress />
			</DialogTitle>
			</Dialog>
		</div>
	);
};

export default Members;
