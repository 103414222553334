export const countries = [
	{id: 1, name: 'Afghanistan'},
	{id: 2, name: 'Albania'},
	{id: 3, name: 'Algeria'},
	{id: 4, name: 'Andorra'},
	{id: 5, name: 'Angola'},
	{id: 6, name: 'Antigua and Barbuda'},
	{id: 7, name: 'Argentina'},
	{id: 8, name: 'Armenia'},
	{id: 9, name: 'Australia'},
	{id: 10, name: 'Austria'},
	{id: 11, name: 'Azerbaijan'},
	{id: 12, name: 'Bahamas'},
	{id: 13, name: 'Bahrain'},
	{id: 14, name: 'Bangladesh'},
	{id: 15, name: 'Barbados'},
	{id: 16, name: 'Belarus'},
	{id: 17, name: 'Belgium'},
	{id: 18, name: '  Belize'},
	{id: 19, name: 'Benin'},
	{id: 20, name: 'Bhutan'},
	{id: 21, name: 'Bolivia'},
	{id: 22, name: 'Bosnia and Herzegovina'},
	{id: 23, name: 'Botswana'},
	{id: 24, name: ' Brazil'},
	{id: 25, name: 'Brunei '},
	{id: 26, name: 'Bulgaria'},
	{id: 27, name: 'Burkina Faso'},
	{id: 28, name: 'Burundi'},
	{id: 29, name: "Côte d'Ivoire"},
	{id: 30, name: 'Cabo Verde'},
	{id: 31, name: 'Cambodia'},
	{id: 32, name: 'Cameroon'},
	{id: 33, name: 'Canada'},
	{id: 35, name: 'Central African Republic'},
	{id: 36, name: 'Chad'},
	{id: 37, name: 'Chile'},
	{id: 38, name: 'China'},
	{id: 39, name: 'Colombia'},
	{id: 40, name: 'Comoros'},
	{id: 41, name: 'Congo (Congo-Brazzaville)'},
	{id: 42, name: 'Costa Rica'},
	{id: 43, name: 'Croatia'},
	{id: 44, name: 'Cuba'},
	{id: 45, name: ' Cyprus'},
	{id: 46, name: 'Czechia (Czech Republic)'},
	{id: 47, name: 'Democratic Republic of the Congo'},
	{id: 48, name: 'Denmark'},
	{id: 49, name: 'Djibouti'},
	{id: 50, name: 'Dominica'},
	{id: 51, name: 'Dominican Republic'},
	{id: 52, name: 'Ecuador'},
	{id: 53, name: 'Egypt'},
	{id: 54, name: 'El Salvador'},
	{id: 55, name: 'Equatorial Guinea'},
	{id: 56, name: 'Eritrea'},
	{id: 57, name: 'Estonia'},
	{id: 58, name: 'Eswatini (fmr. "Swaziland")'},
	{id: 59, name: 'Ethiopia'},
	{id: 60, name: 'Fiji'},
	{id: 61, name: 'Finland'},
	{id: 62, name: ' France'},
	{id: 63, name: 'Gabon'},
	{id: 64, name: ' Gambia'},
	{id: 65, name: 'Georgia'},
	{id: 66, name: 'Germany'},
	{id: 67, name: 'Ghana'},
	{id: 68, name: ' Greece'},
	{id: 69, name: 'Grenada'},
	{id: 70, name: 'Guatemala'},
	{id: 71, name: ' Guinea'},
	{id: 72, name: 'Guinea-Bissau'},
	{id: 73, name: ' Guyana'},
	{id: 74, name: 'Haiti'},
	{id: 75, name: 'Holy See'},
	{id: 76, name: 'Honduras'},
	{id: 77, name: 'Hungary'},
	{id: 78, name: 'Iceland'},
	{id: 79, name: 'India'},
	{id: 81, name: 'Indonesia'},
	{id: 82, name: 'Iran'},
	{id: 83, name: 'Iraq'},
	{id: 84, name: 'Ireland'},
	{id: 85, name: ' Israel'},
	{id: 86, name: 'Italy'},
	{id: 87, name: 'Jamaica'},
	{id: 88, name: 'Japan'},
	{id: 89, name: ' Jordan'},
	{id: 90, name: 'Kazakhstan'},
	{id: 91, name: 'Kenya'},
	{id: 92, name: 'Kiribati'},
	{id: 93, name: ' Kuwait'},
	{id: 94, name: 'Kyrgyzstan'},
	{id: 95, name: 'Laos'},
	{id: 96, name: ' Latvia'},
	{id: 97, name: 'Lebanon'},
	{id: 98, name: 'Lesotho'},
	{id: 99, name: 'Liberia'},
	{id: 100, name: 'Libya'},
	{id: 101, name: 'Liechtenstein'},
	{id: 102, name: 'Lithuania'},
	{id: 103, name: 'Luxembourg'},
	{id: 104, name: 'Madagascar'},
	{id: 105, name: ' Malawi'},
	{id: 106, name: 'Malaysia'},
	{id: 107, name: 'Maldives'},
	{id: 108, name: 'Mali'},
	{id: 109, name: 'Malta'},
	{id: 110, name: 'Marshall Islands'},
	{id: 111, name: 'Mauritania'},
	{id: 112, name: 'Mauritius'},
	{id: 113, name: ' Mexico'},
	{id: 114, name: 'Micronesia'},
	{id: 115, name: 'Moldova'},
	{id: 116, name: ' Monaco'},
	{id: 117, name: 'Mongolia'},
	{id: 118, name: 'Montenegro'},
	{id: 119, name: 'Morocco'},
	{id: 120, name: 'Mozambique'},
	{id: 121, name: 'Myanmar (formerly Burma)'},
	{id: 122, name: 'Namibia'},
	{id: 123, name: 'Nauru'},
	{id: 124, name: 'Nepal'},
	{id: 125, name: 'Netherlands'},
	{id: 126, name: 'New Zealand'},
	{id: 127, name: 'Nicaragua'},
	{id: 128, name: 'Niger'},
	{id: 129, name: 'Nigeria'},
	{id: 130, name: 'North Korea'},
	{id: 131, name: 'North Macedonia'},
	{id: 132, name: ' Norway'},
	{id: 133, name: 'Oman'},
	{id: 134, name: 'Pakistan'},
	{id: 135, name: 'Palau'},
	{id: 136, name: 'Palestine State'},
	{id: 137, name: ' Panama'},
	{id: 138, name: 'Papua New Guinea'},
	{id: 139, name: 'Paraguay'},
	{id: 140, name: 'Peru'},
	{id: 141, name: 'Philippines'},
	{id: 142, name: ' Poland'},
	{id: 143, name: 'Portugal'},
	{id: 144, name: 'Qatar'},
	{id: 145, name: 'Romania'},
	{id: 146, name: ' Russia'},
	{id: 147, name: ' Rwanda'},
	{id: 148, name: 'Saint Kitts and Nevis'},
	{id: 149, name: 'Saint Lucia'},
	{id: 150, name: 'Saint Vincent and the Grenadines'},
	{id: 151, name: 'Samoa'},
	{id: 152, name: 'San Marino'},
	{id: 153, name: 'Sao Tome and Principe'},
	{id: 154, name: 'Saudi Arabia'},
	{id: 155, name: 'Senegal'},
	{id: 156, name: ' Serbia'},
	{id: 157, name: 'Seychelles'},
	{id: 158, name: 'Sierra Leone'},
	{id: 159, name: 'Singapore'},
	{id: 160, name: 'Slovakia'},
	{id: 161, name: 'Slovenia'},
	{id: 162, name: 'Solomon Islands'},
	{id: 163, name: 'Somalia'},
	{id: 164, name: 'South Africa'},
	{id: 165, name: 'South Korea'},
	{id: 166, name: 'South Sudan'},
	{id: 167, name: 'Spain'},
	{id: 168, name: 'Sri Lanka'},
	{id: 169, name: 'Sudan'},
	{id: 170, name: 'Suriname'},
	{id: 171, name: ' Sweden'},
	{id: 172, name: 'Switzerland'},
	{id: 173, name: 'Syria'},
	{id: 174, name: 'Tajikistan'},
	{id: 175, name: 'Tanzania'},
	{id: 176, name: 'Thailand'},
	{id: 177, name: 'Timor-Leste'},
	{id: 178, name: 'Togo'},
	{id: 179, name: 'Tonga'},
	{id: 180, name: 'Trinida18d and Tobago'},
	{id: 181, name: 'Tunisia'},
	{id: 182, name: ' Turkey'},
	{id: 183, name: 'Turkmenistan'},
	{id: 184, name: ' Tuvalu'},
	{id: 185, name: ' Uganda'},
	{id: 186, name: 'Ukraine'},
	{id: 187, name: 'United Arab Emirates'},
	{id: 188, name: 'United Kingdom'},
	{id: 189, name: 'United States of America'},
	{id: 190, name: 'Uruguay'},
	{id: 191, name: 'Uzbekistan'},
	{id: 192, name: 'Vanuatu'},
	{id: 193, name: 'Venezuela'},
	{id: 194, name: 'Vietnam'},
	{id: 195, name: 'Yemen'},
	{id: 196, name: ' Zambia'},
	{id: 197, name: 'Zimbabwe'},
];
