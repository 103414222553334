import {useState} from 'react';

const CheckboxTitle = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [isOpen, setIsOpen] = useState(false);

	// function toggleModal() {
	// 	setIsOpen(!isOpen);
	// }

	return (
		<div
			className="title-wrap "
			isOpen={isOpen}
			onRequestClose={props.onRequestClose}
		>
			<p className="checkboxTitle">{props.checkboxTitle}</p>
			<img
				id="checkbox-x"
				src="/images/Icon Close.svg"
				onRequestClose={props.onRequestClose}
				onClick={props.onClick}
				alt="x"
			/>
		</div>
	);
};

export default CheckboxTitle;
