export const interests = [
	{id: 1, name: 'Training opportunity'},
	{id: 2, name: 'Job opportunity'},
	{id: 3, name: 'Research'},
	{id: 4, name: 'Empowerment'},
	{id: 5, name: 'Funding opportunity'},
	{id: 6, name: 'Consulting'},
	{id: 7, name: 'Resources'},
	{id: 8, name: 'Publication'},
	{id: 9, name: 'Education'},
];
