import React from 'react';
import MemberBadge from '../../pages/Members/MemberBadge';
import { useLocation } from 'react-router-dom';
import './MenuBar.css';

const MenuBar = (props) => {
	const url = useLocation();

	return (
		<div className="menu-bar">
			<div className="logo">
				<a href="/home">
					<img src="/images/logo.png" alt="logo" />
				</a>
			</div>

			<div className="nav">
				<a
					className={url.pathname === '/home' ? 'active' : 'inactive'}
					href="/home"
				>
					{' '}
					Home
				</a>
				<a
					className={url.pathname === '/members' ? 'active' : 'inactive'}
					href="/members"
				>
					Members
				</a>
				<MemberBadge user={props.user} users={props.users} />

				<div className="user">
					<a href="/about-me">
						<img style={{ width: '50px', height: '50px', borderRadius: '100%' }} src={props.user.image_url ? props.user.image_url : "/images/Male 2.png"} alt="User" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default MenuBar;
