import React, { useState } from 'react';
import Modal from 'react-modal';
import Card from 'react-bootstrap/Card';
import MemberAboutMeMobile from '../Members/MemberAboutMeMobile';
import MemberAboutMeDesktop from '../Members/MemberAboutMeDesktop ';
import { statusEnum } from "../../enums.js";

const YourConnection = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [mem, setMem] = useState({});

	function toggleModalOpen(member) {
		setIsOpen(!isOpen);
		// console.log(e);
		// let temp = props.data.filter((m) => m.user_name === e.target.outerText);
		setMem(member);
	}

	function toggleModalClose() {
		setIsOpen(!isOpen);
		setMem({});
	}

	// function toggleModal() {
	// 	setIsOpen(!isOpen);
	// }

	const myConnections = props.members.filter((member) =>
		member.id !== props.currentUser.id &&
		member.status === statusEnum.ACCEPTED
	);

	return (
		<div className="guidelines">
			{/* <img
				src="/images/Icon Close.svg"
				onRequestClose={props.toggleModal}
				onClick={props.toggleModal}
				alt="x"
				id="x"
			/> */}
			<div className="guidelines-header">
				<div id="btm16" className="guidelines-title ">
					Your Connections
				</div>
			</div>
			<div>
				<div>
					{/* we need to choose which id is chelsi.   */}
					{myConnections.map((member, key) => {
						return (
							<Card
								id="connection"
								className="memeber-card"
								onClick={() => toggleModalOpen(member)}
								key={key}
							>
								<Card.Title>
									<img
										className="comment-img"
										src={member.image_url ? member.image_url: '/images/Male 2.png'}
										alt="Topic Icon"
										style={{width: '50px', height: '50px', borderRadius: '100%'}}
									/>

									<div className="parent">
										<div className="card-text">
											<p className="topic-title">{member.user_name} </p>
										</div>
									</div>
								</Card.Title>
							</Card>
						);
					})}
				</div>
			</div>
			<Modal
				id="member-modal"
				isOpen={isOpen}
				onRequestClose={toggleModalClose}
				ariaHideApp={false}
			>
				<MemberAboutMeMobile me={mem} toggleModal={toggleModalClose} languages={props.languages} goals={props.goals} interests={props.interests} hobbies={props.hobbies}/>
				<MemberAboutMeDesktop
					id="post-modal"
					me={mem}
					toggleModal={toggleModalClose}
					languages={props.languages} goals={props.goals} interests={props.interests} hobbies={props.hobbies}
				/>
			</Modal>
		</div>
	);
};

export default YourConnection;
