/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
import InputPullDown from '../../components/InputPullDown';
import Button from '../../components/Button';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import InputBox from '../../components/InputBox';
import { countries } from '../../components/Countries';
import LangCheckboxes from './FormComponents/LangCheckboxes';
import HobbyCheckboxes from './FormComponents/HobbyCheckboxes';
import CheckboxTitle from './FormComponents/CheckboxTitle';
import AboutBox from '../../components/AboutBox';
//import Another from './FormComponents/Another';
import GoalsCheckboxes from './FormComponents/GoalsCheckboxes';
import InterestCheckboxes from './FormComponents/InterestCheckboxes';
import AddYourPicture from '../../components/AddYourPicture';
import FileUpload from '../DiscussionForum/FileUpload';
//import AboutMe from '../View Profile/AboutMe';
import { endpoints } from "../../enums.js";

const EditYourProfile = (props) => {
	const history = useHistory();

	const [isLangOpen, setIsLangOpen] = useState(false);
	const [isGoalOpen, setIsGoalOpen] = useState(false);
	const [isInterestOpen, setIsInterestOpen] = useState(false);
	const [isHobbyOpen, setIsHobbyOpen] = useState(false);

	function toggleLangModal() {
		setIsLangOpen(!isLangOpen);
	}
	function toggleGoalModal() {
		setIsGoalOpen(!isGoalOpen);
	}
	function toggleInterestModal() {
		setIsInterestOpen(!isInterestOpen);
	}
	function toggleHobbyModal() {
		setIsHobbyOpen(!isHobbyOpen);
	}
	const [values, setValues] = useState({});
	const [languages, setLanguages] = React.useState([])
	const [goals, setGoals] = React.useState([])
	const [interests, setInterests] = React.useState([])
	const [hobbies, setHobbies] = React.useState([])
	const [userFiles, setUserFiles] = useState([]);

	let [userLanguages, setUserLanguages] = useState([]);
	let [userGoals, setUserGoals] = useState([]);
	let [userInterests, setUserInterests] = useState([]);
	let [userHobbies, setUserHobbies] = useState([]);

	let [userPersonalGoals, setUserPersonalGoals] = useState([]);
	let [userPersonalInterests, setUserPersonalInterests] = useState([]);
	let [userPersonalHobbies, setUserPersonalHobbies] = useState([]);
	// eslint-disable-next-line no-unused-vars
	let [userPersonalContacts, setUserPersonalContacts] = useState([]);
	// const [name, setName] = useState(new Set());

	const setInitialValues = () => {
		// eslint-disable-next-line no-undef
		setValues(props.user);
		getFiles(props.user);
		setUserLanguages(props.user.language);

		setUserGoals(props.user.goal);
		setUserPersonalGoals(props.user.goal_other);

		setUserInterests(props.user.interest);
		setUserPersonalInterests(props.user.interest_other)

		setUserHobbies(props.user.hobby);
		setUserPersonalHobbies(props.user.hobby_other)

		setUserPersonalContacts(props.user.contact);
	}

	const getLanguages = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.languagesGet(null, null, additionalParams)
			.then((res) => {
				setLanguages(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getGoals = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.goalsGet(null, null, additionalParams)
			.then((res) => {
				setGoals(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getInterests = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.interestsGet(null, null, additionalParams)
			.then((res) => {
				setInterests(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getHobbies = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.hobbiesGet(null, null, additionalParams)
			.then((res) => {
				setHobbies(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getFiles = async (user) => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const additionalParams = {
			queryParams: {
				user: await getCurrentUser()
			}
		};
		const params = {
			userId: user.id
		}
		apigClient.userUserIdFilesGet(params, {}, additionalParams)
			.then((res) => {
				setUserFiles(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		setInitialValues()
		getLanguages()
		getGoals()
		getInterests()
		getHobbies()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// eslint-disable-next-line no-unused-vars
	const [saved, setSaved] = useState(false);

	const handleNameChange = (event) => {
		setValues({ ...values, user_name: event.target.value });
	};

	const handleOccupationChange = (event) => {
		setValues({ ...values, occupation: event.target.value });
	};

	const handleStateRegionChange = (event) => {
		setValues({ ...values, state: event.target.value });
	};

	const handleLanguageChange = (newLang) => {
		console.log(newLang)
		var newValues = { ...values };
		newValues.language = newLang
		setUserLanguages(newLang);
		setValues(newValues);
		setIsLangOpen(false)
	};

	const handleGoalsChange = (newGoals) => {
		var newValues = { ...values };
		newValues.goal = newGoals
		setUserGoals(newGoals);
		setValues(newValues);
		setIsGoalOpen(false)
	};

	const handleInterestsChange = (newInterests) => {
		var newValues = { ...values };
		newValues.interest = newInterests
		setUserInterests(newInterests);
		setValues(newValues);
		setIsInterestOpen(false)
	};

	const handleHobbiesChange = (newHobbies) => {
		var newValues = { ...values };
		newValues.hobby = newHobbies
		setUserHobbies(newHobbies);
		setValues(newValues);
		setIsHobbyOpen(false)
	};

	const [goalsToUpdate, setGoalsToUpdate] = useState([]);
	const [goalsToRemove, setGoalsToRemove] = useState([]);
	const [goalsToAdd, setGoalsToAdd] = useState([]);

	const handlePersonalGoalsChange = (newPersonalGoals) => {
		console.log("newPersonalGoals", newPersonalGoals);
		setUserPersonalGoals(newPersonalGoals);
		setGoalsToUpdate(
			newPersonalGoals
				.filter((goal) => goal.id !== undefined && goal.another)
				.map(({ another, id }) => {
					return {
						id,
						goal_name: another
					}
				})
		);
		setGoalsToAdd(
			newPersonalGoals
				.filter((goal) => !goal.id && goal.another)
				.map((goal) => ({ goal_name: goal.another }))
		);

		const goalIdsArray = newPersonalGoals
			.filter((goal) => goal.id !== undefined)
			.map((goal) => goal.id);
		setGoalsToRemove(userPersonalGoals.map(goal => goal.id).filter((id) => id && !goalIdsArray.includes(id)));
	}

	const [interestsToUpdate, setInterestsToUpdate] = useState([]);
	const [interestsToRemove, setInterestsToRemove] = useState([]);
	const [interestsToAdd, setInterestsToAdd] = useState([]);

	const handlePersonalInterestsChange = (newPersonalInterests) => {
		console.log("newPersonalInterests", newPersonalInterests);
		setUserPersonalInterests(newPersonalInterests);
		setInterestsToUpdate(
			newPersonalInterests
				.filter((interest) => interest.id !== undefined && interest.another)
				.map(({ another, id }) => {
					return {
						id,
						interest_name: another
					}
				})
		);
		setInterestsToAdd(
			newPersonalInterests
				.filter((interest) => !interest.id && interest.another)
				.map((interest) => ({ interest_name: interest.another }))
		);

		const interestIdsArray = newPersonalInterests
			.filter((interest) => interest.id !== undefined)
			.map((interest) => interest.id);
		setInterestsToRemove(userPersonalInterests.map(interest => interest.id).filter((id) => id && !interestIdsArray.includes(id)));
	}

	const [hobbiesToUpdate, setHobbiesToUpdate] = useState([]);
	const [hobbiesToRemove, setHobbiesToRemove] = useState([]);
	const [hobbiesToAdd, setHobbiesToAdd] = useState([]);

	const handlePersonalHobbiesChange = (newPersonalHobbies) => {
		console.log("newPersonalHobbiess", newPersonalHobbies);
		setUserPersonalGoals(newPersonalHobbies);
		setHobbiesToUpdate(
			newPersonalHobbies
				.filter((hobby) => hobby.id !== undefined && hobby.another)
				.map(({ another, id }) => {
					return {
						id,
						hobby_name: another
					}
				})
		);
		setHobbiesToAdd(
			newPersonalHobbies
				.filter((hobby) => !hobby.id && hobby.another)
				.map((hobby) => ({ hobby_name: hobby.another }))
		);

		const hobbyIdsArray = newPersonalHobbies
			.filter((hobby) => hobby.id !== undefined)
			.map((hobby) => hobby.id);
		setHobbiesToRemove(userPersonalHobbies.map(hobby => hobby.id).filter((id) => id && !hobbyIdsArray.includes(id)));
	}

	const handleAboutChange = (event) => {
		setValues({ ...values, about: event.target.value });
	};

	function onSelectChange(event) {
		setValues((prevState) => {
			return { ...prevState, country: event.target.value };
		});
	}

	const handleEmailChange = (event) => {
		setValues({ ...values, email: event.target.value });
	};

	const handleWhatsAppChange = (event) => {
		setValues({ ...values, whatsapp: event.target.value });
	};

	const handleTwitterChange = (event) => {
		setValues({ ...values, twitter: event.target.value });
	};

	const handleFacebookChange = (event) => {
		setValues({ ...values, facebook: event.target.value });
	};

	const handleLinkedInChange = (event) => {
		setValues({ ...values, linkedin: event.target.value });
	};

	const handleInstagramnChange = (event) => {
		setValues({ ...values, instagram: event.target.value });
	};
	// const [anotherList, setAnotherList] = useState([{ another: '' }]);

	// const handleAnotherChange = (e, index) => {
	// 	// eslint-disable-next-line no-unused-vars
	// 	const { name, value } = e.target;
	// 	const list = [...anotherList];
	// 	console.log(list)
	// 	list[index].contact_info = value;
	// 	setAnotherList(list);
	// 	setValues({ ...values, another: e.target.value });
	// };

	// const handleRemoveClick = (index) => {
	// 	const list = [...anotherList];
	// 	list.splice(index, 1);
	// 	setAnotherList(list);
	// };

	// const handleAddClick = () => {
	// 	let currentArray = [...anotherList]
	// 	currentArray.push({ another: '' })
	// 	setAnotherList(currentArray);
	// };

	const [profileImg, setProfileImg] = useState({ preview: "", raw: "" });

	const handleProfilePictureChange = e => {
		if (e.target.files.length) {
			setProfileImg({
				preview: URL.createObjectURL(e.target.files[0]),
				raw: e.target.files[0]
			});
		}
	};

	const uploadToS3 = async (file) => {
		try {
			const { name, type } = file;
			const regex = /\.(?!.*\.)(.*)/g;
			const abbreviatedType = regex.exec(name)[1];
			const blobData = file;
			// eslint-disable-next-line no-undef
			const apigClient = apigClientFactory.newClient(
				lambdaConfig
			);
			const currentUser = await getCurrentUser();
			const additionalParams = {
				queryParams: {
					user: currentUser,
					abbrev: encodeURIComponent(abbreviatedType),
					contentType: encodeURIComponent(type)
				}
			};
			const params = {};
			const body = {};

			const response = await apigClient.fileUploadGet(params, body, additionalParams);
			const { uploadURL, Key } = JSON.parse(response.data.url);

			// Upload to s3
			await fetch(uploadURL, {
				method: "PUT",
				body: blobData
			});

			return Key;
		} catch (err) {
			// TODO: handle error
			console.log(err);
		}
	}

	let [addedFiles, setFiles] = useState([]);

	const handleFileUpload = (e) => {
		if (e.target.files.length) {
			setFiles([...addedFiles, e.target.files[0]]);
		}
	};

	const submitFiles = async () => {
		if (addedFiles.length > 0) {
			await Promise.all(
				addedFiles.map(async (file) => {
					const key = await uploadToS3(file);
					const fileUrl = `${endpoints.S3_BUCKET}/${key}`;

					const addFileBody = {
						file_name: file.name,
						file_source: fileUrl,
						file_type: file.type
					};

					// eslint-disable-next-line no-undef
					const apigClient = apigClientFactory.newClient(
						lambdaConfig
					);

					const additionalParams = {
						queryParams: {
							user: await getCurrentUser()
						}
					};

					const userParams = {
						userId: values.id
					};

					apigClient.userUserIdFilePost(userParams, addFileBody, additionalParams)
						.then((res) => {
							console.log(res);
						})
						.catch((err) => {
							// TODO: handle error
							console.log(err);
						})
				})
			);
		}
	}

	const updateUserPersonalGoals = async (apigClient, additionalParams) => {
		// Update user personal goals
		await Promise.all(goalsToUpdate.map((goal) => {
			apigClient
				.userPersonalGoalGoalIdPost(
					{ goalId: goal.id },
					{ goal_name: goal.goal_name },
					additionalParams
				)
				.then(async (res) => {
					console.log(res);
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));

		// Add user personal goals
		await Promise.all(goalsToAdd.map((goal) => {
			apigClient.userPersonalGoalPost({}, goal, additionalParams)
				.then(async (res) => {
					console.log(res);
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));

		// Remove user personal goals
		await Promise.all(goalsToRemove.map((goal) => {
			apigClient.userPersonalGoalGoalIdRemovePost({ goalId: goal }, {}, additionalParams)
				.then(async (res) => {
					console.log(res)
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));
	}

	const updateUserPersonalInterests = async (apigClient, additionalParams) => {
		// Update user personal goals
		await Promise.all(interestsToUpdate.map((interest) => {
			apigClient
				.userPersonalInterestInterestIdPost(
					{ interestId: interest.id },
					{ interest_name: interest.interest_name },
					additionalParams
				)
				.then(async (res) => {
					console.log(res);
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));

		// Add user personal goals
		await Promise.all(interestsToAdd.map((interest) => {
			apigClient.userPersonalInterestPost({}, interest, additionalParams)
				.then(async (res) => {
					console.log(res);
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));

		// Remove user personal goals
		await Promise.all(interestsToRemove.map((interest) => {
			apigClient.userPersonalGoalGoalIdRemovePost({ interestId: interest }, {}, additionalParams)
				.then(async (res) => {
					console.log(res)
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));
	}

	const updateUserPersonalHobbies = async (apigClient, additionalParams) => {
		// Update user personal goals
		await Promise.all(hobbiesToUpdate.map((hobby) => {
			apigClient
				.userPersonalHobbyHobbyIdPost(
					{ hobbyId: hobby.id },
					{ hobby_name: hobby.hobby_name },
					additionalParams
				)
				.then(async (res) => {
					console.log(res);
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));

		// Add user personal goals
		await Promise.all(hobbiesToAdd.map((hobby) => {
			apigClient.userPersonalHobbyPost({}, hobby, additionalParams)
				.then(async (res) => {
					console.log(res);
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));

		// Remove user personal goals
		await Promise.all(hobbiesToRemove.map((hobby) => {
			apigClient.userPersonalHobbyHobbyIdRemovePost({ hobbyId: hobby }, {}, additionalParams)
				.then(async (res) => {
					console.log(res)
					return true;
				})
				.catch((err) => {
					console.log(err);
				});
		}));
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const userParams = {
			userId: values.id
		};

		if (profileImg.preview && profileImg.raw) {
			const key = await uploadToS3(profileImg.raw);
			values["image_url"] = `${endpoints.S3_BUCKET}/${key}`;
		}

		console.log(values)

		const body = values;

		await updateUserPersonalGoals(apigClient, additionalParams);
		await updateUserPersonalInterests(apigClient, additionalParams);
		await updateUserPersonalHobbies(apigClient, additionalParams);

		if (body.contact) {
			const postBody = {
				title: "Other",
				contact_info: body.another
			}
			apigClient.userUserIdContactPost(userParams, postBody, additionalParams)
				.then(async (res) => {
					console.log("success")
				})
				.catch((err) => {
					console.log(err);
				});
		}


		apigClient.userUserIdPost(userParams, body, additionalParams)
			.then(async (res) => {
				console.log("success")
				await submitFiles();
				setSaved(true);
				history.push("/about-me");
			})
			.catch((err) => {
				console.log(err);
			});

	};

	return (
		<div>
			<p id="create-profile-title">Edit Your Profile</p>
			<div id="visibility-top">
				<img src="/images/lock.png" id="lock" alt="lock" />
				<p id="visibility-text">
					Your profile is visible to members only. <br />
					All fields are optional.
				</p>
			</div>

			<div className="addPicture">
				<AddYourPicture
					image={profileImg.preview || values.image_url}
					text="Edit your profile picture"
					handleChange={handleProfilePictureChange}
				/>
			</div>

			<div className="CreateYourProfile">
				<section className="left profile-border">
					<form onSubmit={handleSubmit}>
						<p className="subtitle">Introduce Yourself</p>
						<InputBox
							title="Name"
							value={values.user_name}
							onChange={handleNameChange}
						/>

						<InputBox
							title="Occupation"
							value={values.occupation}
							onChange={handleOccupationChange}
						/>
						{/* <SelectCountry onSelectChange={onSelectChange} /> */}

						<InputPullDown
							data={countries}
							value={values.country}
							onChange={onSelectChange}
							text={'Country'}
							option={'Choose Your Country'}
							countries={true}
						/>

						<InputBox
							title="State/Region"
							value={values.state}
							onChange={handleStateRegionChange}
						/>

						<p className="input-title">Language(s)</p>
						<div className="edit-list">
							{languages.map((language, key) => {
								if (userLanguages.includes(language.id)) {
									return (
										<p className="edit-me-box" key={key}>
											{language.language_name}
										</p>
									)
								}
							})}
							<img
								className="edit-img"
								src="/images/edit.svg"
								onClick={toggleLangModal}
								alt=""
							/>
						</div>

						<Modal
							ariaHideApp={false}
							isOpen={isLangOpen}
							onRequestClose={toggleLangModal}
						>
							<CheckboxTitle
								checkboxTitle="Language(s)"
								onClick={toggleLangModal}
							/>
							<LangCheckboxes
								className="modal-box"
								handleLanguageChange={handleLanguageChange}
								value={languages}
								userLanguages={userLanguages}
							/>
						</Modal>

						<p className="input-title">Goal(s)</p>
						<div className="edit-list">
							{
								goals.map((goal, key) => {
									if (userGoals.includes(goal.id)) {
										return (
											<p className=" edit-me-box " key={key}>
												{goal.goal_name}
											</p>
										)
									}
								})
							}
							{
								userPersonalGoals.map((goal, key) => {
									const goalName = goal.goal_name || goal.another;
									return goalName && (
										<p className=" edit-me-box " key={`${key}-personal`}>
											{goalName}
										</p>
									)
								})
							}
							<img
								className="edit-img"
								src="/images/edit.svg"
								onClick={toggleGoalModal}
								alt=""
							/>
						</div>
						<Modal
							ariaHideApp={false}
							isOpen={isGoalOpen}
							onRequestClose={toggleGoalModal}
						>
							<CheckboxTitle
								checkboxTitle={'Goal(s)'}
								onClick={toggleGoalModal}
							/>
							<GoalsCheckboxes
								className="modal-box"
								toggleModal={toggleGoalModal}
								value={goals}
								userGoals={userGoals}
								userPersonalGoals={userPersonalGoals}
								handleGoalsChange={handleGoalsChange}
								handlePersonalGoalsChange={handlePersonalGoalsChange}
							/>
						</Modal>

						<p className="input-title">Interest(s)</p>
						<div className="edit-list">
							{interests.map((interest, key) => {
								if (userInterests.includes(interest.id)) {
									return (
										<p className=" edit-me-box " key={key}>
											{interest.interest_name}
										</p>
									)
								}
							})}
							{
								userPersonalInterests.map((interest, key) => {
									const interestName = interest.interest_name || interest.another;
									return interestName && (
										<p className=" edit-me-box " key={`${key}-personal`}>
											{interestName}
										</p>
									)
								})
							}
							<img
								className="edit-img"
								src="/images/edit.svg"
								onClick={toggleInterestModal}
								alt=""
							/>
						</div>

						<Modal
							ariaHideApp={false}
							isOpen={isInterestOpen}
							onRequestClose={toggleInterestModal}
						>
							<CheckboxTitle
								checkboxTitle={'Interest(s)'}
								onClick={toggleInterestModal}
							/>
							<InterestCheckboxes
								className="modal-box"
								toggleModal={toggleInterestModal}
								value={interests}
								userInterests={userInterests}
								userPersonalInterests={userPersonalInterests}
								handleInterestsChange={handleInterestsChange}
								handlePersonalInterestsChange={handlePersonalInterestsChange}
							/>
						</Modal>

						<p className="input-title">Hobby(ies)</p>
						<div className="edit-list">
							{hobbies.map((hobby, key) => {
								if (userHobbies.includes(hobby.id)) {
									return (
										<p className=" edit-me-box " key={key}>
											{hobby.hobby_name}
										</p>
									)
								}
							})}
							{
								userPersonalHobbies.map((hobby, key) => {
									const hobbyName = hobby.hobby_name || hobby.another;
									return hobbyName && (
										<p className=" edit-me-box " key={`${key}-personal`}>
											{hobbyName}
										</p>
									)
								})
							}
							<img
								className="edit-img"
								src="/images/edit.svg"
								onClick={toggleHobbyModal}
								alt=""
							/>
						</div>

						<Modal
							ariaHideApp={false}
							isOpen={isHobbyOpen}
							onRequestClose={toggleHobbyModal}
						>
							<CheckboxTitle
								checkboxTitle={'Hobby(ies)'}
								onClick={toggleHobbyModal}
							/>
							<HobbyCheckboxes
								className="modal-box"
								toggleModal={toggleHobbyModal}
								value={hobbies}
								userHobbies={userHobbies}
								userPersonalHobbies={userPersonalHobbies}
								handleHobbiesChange={handleHobbiesChange}
								handlePersonalHobbiesChange={handlePersonalHobbiesChange}
							/>
						</Modal>

						{/* <p className="input-title">Hobby(ies)</p>
						<div className="edit-list">
							<p className=" edit-me-box  ">Reading</p>
							<p className=" edit-me-box  ">Cooking</p>
							<img
								className="edit-img"
								src="/images/edit.svg"
								onClick={toggleModal}
								alt=""
							/>
						</div>

						<Modal
							ariaHideApp={false}
							isOpen={isOpen}
							onRequestClose={toggleModal}
						>
							<CheckboxTitle checkboxTitle="Hobby(ies)" onClick={toggleModal} />
							<HobbyCheckboxes
								className="modal-box"
								toggleModal={toggleModal}
							/>
						</Modal> */}

						<AboutBox
							id="about-input"
							value={values.about}
							onChange={handleAboutChange}
						/>
						<FileUpload
							files={userFiles}
							handleFileUpload={handleFileUpload}
						/>
						{
							userFiles.length > 0 ?
								<div className="file-list-container">
									<p className="input-title">Files</p>
									{userFiles.concat(addedFiles).map((file, id) => {
										return (
											<a target="_blank" without rel="noreferrer" href={file.file_source} key={id}>
												{file.name ?
													<span>{file.name}</span>
													: <span>{file.file_name}</span>}
											</a>
										);
									})}
								</div> :
								<div></div>
						}
					</form>
				</section>

				<section className="right profile-border ">
					<form onSubmit={handleSubmit}>
						<div className="subtitle">Contact Information</div>
						<div className="visibility">
							<img src="/images/lock.png" id="lock" alt="lock" />
							<p id="visibility-text">
								Contact information becomes visible only when the connection
								request is accepted.
							</p>
						</div>{' '}
						<InputBox
							title="Email"
							value={values.email}
							onChange={handleEmailChange}
						/>
						<InputBox
							title="WhatsApp"
							value={values.whatsapp}
							onChange={handleWhatsAppChange}
						/>
						<InputBox
							title="Twitter"
							value={values.twitter}
							onChange={handleTwitterChange}
						/>
						<InputBox
							title="Facebook"
							value={values.facebook}
							onChange={handleFacebookChange}
						/>
						<InputBox
							title="LinkedIn"
							value={values.linkedin}
							onChange={handleLinkedInChange}
						/>
						<InputBox
							title="Instagram"
							value={values.instagram}
							onChange={handleInstagramnChange}
						/>
						{/* <p className="input-title">Add another</p>
						<div className="another">
							{anotherList.length === 0 ?
								<div className="another-box" id={props.addId}>
								<input
									name="another"
									placeholder="Social Media Platform/Username"
									className="input-box "
									//value={x.contact_info}
									onChange={(e) => handleAnotherChange(e, 0)}
								/>

								<div className="btn-box">
									 {anotherList.length !== 1 && (
										<button
											className="another-btn"
											onClick={() => handleRemoveClick(i)}
										>
											Remove
										</button>
									)}
									{anotherList.length - 1 === i && (
										<button
											className="another-btn"
											onClick={handleAddClick}
										>
											Add
										</button>

								</div>
							</div>
							: (
								<div>
								{anotherList.map((x, i) => {
									return (
										<div className="another-box" id={props.addId} key={i}>
											<input
												name="another"
												placeholder="Social Media Platform/Username"
												className="input-box "
												value={x.contact_info}
												onChange={(e) => handleAnotherChange(e, i)}
											/>

											<div className="btn-box">
													<button
														className="another-btn"
														onClick={() => handleRemoveClick(i)}
													>
														Remove
													</button>


											</div>
										</div>
									);
								})}
								<button
									className="another-btn"
									onClick={handleAddClick}
								>
									Add
								</button>
								</div>
							)}

						</div> */}
						{/* <a href="/about-me">
							<Button type="submit" id="save-btn" btnText={'Save'} />
						</a> */}
					</form>

					{/* <a id="no-underline" href="/about-me"> */}
					<Button type="submit no-underline" id="save-btn" btnText={'Save'} onClick={handleSubmit} />
					{/* </a> */}
				</section>
			</div>
		</div>
	);
};

export default EditYourProfile;
