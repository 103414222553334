import React, { useEffect, useState } from 'react';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
import Guidelines from './Guidelines';
import PostSomething from '../DiscussionForum/PostSomething';
import DiscussionTopic from '../DiscussionForum/DiscussionTopic';
import './Home.css';
import Success from '../../components/Success';
import mockdataTopic from '../../mockdataTopic';
import { endpoints } from "../../enums.js";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';


const Home = (props) => {
	const [successOpen, setSuccessOpen] = useState(false);
	const [topics, setTopics] = useState(mockdataTopic);
	const [loading, setLoading] = useState(false);

	const uploadToS3 = async (file) => {
		try {
			const { name, type } = file;
			const regex = /\.(?!.*\.)(.*)/g;
			const abbreviatedType = regex.exec(name)[1];
			const blobData = file;
			// eslint-disable-next-line no-undef
			const apigClient = apigClientFactory.newClient(
				lambdaConfig
			);
			const currentUser = await getCurrentUser();
			const additionalParams = {
				queryParams: {
					user: currentUser,
					abbrev: encodeURIComponent(abbreviatedType),
					contentType: encodeURIComponent(type)
				}
			};
			const params = {};
			const body = {};

			const response = await apigClient.fileUploadGet(params, body, additionalParams);
			const { uploadURL, Key } = JSON.parse(response.data.url);

			// Upload to s3
			await fetch(uploadURL, {
				method: "PUT",
				body: blobData
			});

			return Key;
		} catch (err) {
			// TODO: handle error
			console.log(err);
		}
	}

	let [addedFiles, setFiles] = useState([]);

	const handleFileUpload = (e) => {
		setFiles([e])
	};

	const postSuccessFunc = async (postDetails) => {
		setSuccessOpen(true);
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();

		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};

		let topic;
		if (postDetails.topic === "Introduce Yourself") {
			topic = 1
		} else if (postDetails.topic === "Consulting Opportunities") {
			topic = 2
		} else if (postDetails.topic === "Survivor Alliance Updates") {
			topic = 3
		} else if (postDetails.topic === "Anti-Trafficking Sector") {
			topic = 4
		} else if (postDetails.topic === "Survivor Leadership Resources") {
			topic = 5
		}

		const topicParams = {
			topicId: topic
		};

		const body = {
			post_title: postDetails.title,
			post_content: postDetails.description
		}

		await apigClient.topicsTopicIdPostPost(topicParams, body, additionalParams)
			.then(async (res) => {
				if (addedFiles.length > 0) {
					await Promise.all(
						addedFiles.map(async (file) => {
							const key = await uploadToS3(file);
							const fileUrl = `${endpoints.S3_BUCKET}/${key}`;

							const addFileBody = {
								file_name: file.name,
								file_source: fileUrl,
								file_type: file.type
							};

							// eslint-disable-next-line no-undef
							const apigClient = apigClientFactory.newClient(
								lambdaConfig
							);

							const additionalParams = {
								queryParams: {
									user: await getCurrentUser()
								}
							};

							const topicParams = {
								postId: res.data.id,
							};

							apigClient.postPostIdFilePost(topicParams, addFileBody, additionalParams)
								.then((res) => {
									console.log(res);
								})
								.catch((err) => {
									// TODO: handle error
									console.log(err);
								})
						})
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const closeSuccess = () => {
		setSuccessOpen(false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		setLoading(true)
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.topicsGet(null, null, additionalParams)
			.then((res) => {
				for (let i = 0; i < res.data.length; i++) {
					var date = new Date(Number(res.data[i].most_recent_post_date) * 1000);
					if (res.data[i].most_recent_post_date) {
						res.data[i].most_recent_post_date = date.toLocaleDateString() + " " + date.toLocaleTimeString('en-US');
					}
				}
				var sorted = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
				setTopics(sorted)
				setLoading(false)

			})
			.catch((err) => {
				setLoading(false)
				console.log(err);
			});
	}, [])

	return (
		<div>
			<div className="home">
				<div>
					<div className="row">
						<div className="home-left">
							<p className="welcome">Welcome to Digital Town Square!</p>
							<PostSomething
								user={props.user}
								postSuccessFunc={postSuccessFunc}
								handleFileUpload={handleFileUpload}
							/>
							<p id="discussion-title">Discussion Topics</p>

							<DiscussionTopic data={topics} />
						</div>
						<div className="home-right">
							<Guidelines className="guideline" />
						</div>
					</div>
					<Success open={successOpen} handleClose={closeSuccess} SuccessMsg={'Success! Your post has been submitted.'} />
				</div>
			</div>
			<Dialog aria-labelledby="simple-dialog-title" open={loading}>
				<DialogTitle id="simple-dialog-title">Loading...		<CircularProgress />
				</DialogTitle>
			</Dialog>
		</div>
	);
};

export default Home;
