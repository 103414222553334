import React, {useState} from 'react';

function InputPullDown(props) {
	const [data] = useState(props.data);
	//const [selectedData, updateSelectedData] = useState('');

	// function handleChange(event) {
	// 	updateSelectedData(event.target.value);
	// 	if (props.onSelectChange) props.onSelectChange(selectedData);
	// }

	// var convertedData = props.value;
	// for (let i = 0; i < data.length; i++){
	// 	if (data[i].)
	// }
	let options;
	if (props.countries) {
		options = data.map((data) => (
			<option key={data.id} value={data.name}>
				{data.name}
			</option>
		));
	} else {
		options = data.map((data) => (
			<option key={data.id} value={data.id}>
				{data.name}
			</option>
		));
	}


	return (
		<div>
			<div className="input-title country">{props.text}</div>
			<select
				name="customSearch"
				className="custom-search-select country-option"
				onChange={props.onChange}
				value={props.value}
			>
				{props.value ? null : <option>{props.option}</option>}
				{options}
			</select>
		</div>
	);
}

export default InputPullDown;
