import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Modal from 'react-modal';
import MemberAboutMeMobile from './MemberAboutMeMobile';
import MemberAboutMeDesktop from './MemberAboutMeDesktop ';

const MembersList = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [mem, setMem] = useState({});

	const getMembers = () => {
		props.getMembers()
	}

	function toggleModalOpen(member) {
		setIsOpen(!isOpen);
		// console.log(e);
		// console.log(e.target);
		// console.log(e.target.outerText);
		// let temp = props.data.filter((m) => m.user_name === e.target.outerText);
		setMem(member);
	}

	function toggleModalClose() {
		setIsOpen(!isOpen);
		setMem({});
	}

	return (
		<div>
			{props.data.map((member, key) => {
				return (
					<Card
						className="discussion-topic"
						onClick={() => toggleModalOpen(member)}
						key={key}
					>
						<Card.Title>
							<img
								className="comment-img"
								src={member.image_url ? member.image_url : '/images/Male 2.png'}
								alt="Topic Icon"
								style={{ width: '50px', height: '50px', borderRadius: '100%' }}
							/>

							<div className="parent">
								<div className="card-text-member">
									<p
										className="topic-title"
									// onClick={toggleModalOpen}
									>
										{member.user_name}
									</p>

									<p className="post-date">{member.country}</p>
								</div>
							</div>
						</Card.Title>

						<Card.Text>
							<p id="ellipsis">{member.about}</p>
						</Card.Text>
					</Card>

					// </Link>
				);
			})}

			<Modal
				id="member-modal"
				isOpen={isOpen}
				onRequestClose={toggleModalClose}
				ariaHideApp={false}
			>
				<MemberAboutMeMobile toggleModal={toggleModalClose} me={mem} languages={props.languages} goals={props.goals} interests={props.interests} hobbies={props.hobbies} getMembers={getMembers} />
				<MemberAboutMeDesktop
					toggleModal={toggleModalClose}
					id="post-modal"
					me={mem}
					languages={props.languages}
					goals={props.goals}
					interests={props.interests}
					hobbies={props.hobbies}
					getMembers={getMembers}
				/>
			</Modal>
		</div>
	);
};

export default MembersList;
