import React, { useEffect, useState } from 'react';
//import lambdaConfig from "../../aws-exports-lambda.js";
//import getCurrentUser from "../../utils";

const MemberBadge = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [isNew, setIsNew] = useState(false);
	const [currentUser, setCurrentUser] = useState({});

	useEffect(() => {
		setCurrentUser(props.user)
		let setTrue;
		for (let i = 0; i < props.users.length; i++) {
			if (props.users[i].status === "0" && currentUser.id !== +props.users[i].action_user_id) {
				setTrue = true
			}
		}
		setIsNew(setTrue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div>
			{isNew ? (
				<img id="member-badge" src="/images/Notification Dot.svg" alt="Profile Avatar" />
			) : null}
		</div>
	);
};

export default MemberBadge;
