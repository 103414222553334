import React, { useState } from 'react';
import Modal from 'react-modal';
import Guidelines from '../../pages/Home/Guidelines';
import MemberBadge from '../../pages/Members/MemberBadge';
import { useLocation } from 'react-router-dom';
import './MenuBarMobile.css';

const MenuBarMobile = (props) => {
	const url = useLocation();

	const [isOpen, setIsOpen] = useState(false);

	function toggleModal() {
		setIsOpen(!isOpen);
	}

	return (
		<div className="menu-mobile">
			<div>
				<p id="guideline-text">
					This is a safe space for survivors.{' '}
					<span id="guideline-link" onClick={toggleModal}>
						Review our Guidelines{' '}
					</span>
				</p>
			</div>

			<div className="mobile-menu-top">
				<a href="/home">
					<img src="/images/logo.png" id="mobile-logo" alt="logo" />
				</a>
				<a href="/about-me">
					<img style={{ width: '50px', height: '50px', borderRadius: '100%' }} src={props.user.image_url ? props.user.image_url : "/images/Male 2.png"} id="mobile-user" alt="user" />
				</a>
			</div>

			<div className="mobile-nav">
				<a
					className={url.pathname === '/home' ? 'active' : 'inactive'}
					href="/home"
				>
					{' '}
					Home
				</a>
				<a
					className={url.pathname === '/members' ? 'active' : 'inactive'}
					href="/members"
				>
					Members
				</a>
				<MemberBadge user={props.user} users={props.users} />
			</div>

			<Modal
				id="guideline-modal"
				isOpen={isOpen}
				onRequestClose={toggleModal}
				ariaHideApp={false}
			>
				<Guidelines toggleModal={toggleModal} />
			</Modal>
		</div>
	);
};

export default MenuBarMobile;
