import React, { useEffect } from 'react';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
//import userMockData from '../../userMockData';
import AboutMeDesktop from './AboutMeDesktop';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

const AboutMe = (props) => {
	const [userFiles, setUserFiles] = React.useState([])
	const [languages, setLanguages] = React.useState([])
	const [goals, setGoals] = React.useState([])
	const [interests, setInterests] = React.useState([])
	const [hobbies, setHobbies] = React.useState([])
	const [open, setOpen] = React.useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const getLanguages = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.languagesGet(null, null, additionalParams)
			.then((res) => {
				setLanguages(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getGoals = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.goalsGet(null, null, additionalParams)
			.then((res) => {
				setGoals(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getInterests = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.interestsGet(null, null, additionalParams)
			.then((res) => {
				setInterests(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const getHobbies = async () => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		apigClient.hobbiesGet(null, null, additionalParams)
			.then((res) => {
				setHobbies(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}


	const getFiles = async (user) => {
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const additionalParams = {
			queryParams: {
				user: await getCurrentUser()
			}
		};
		const params = {
			userId: user.id
		}
		apigClient.userUserIdFilesGet(params, {}, additionalParams)
			.then((res) => {
				setUserFiles(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		getFiles(props.user);
		getLanguages()
		getGoals()
		getInterests()
		getHobbies()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<div>
			<AboutMeDesktop me={props.user} languages={languages} goals={goals} interests={interests} hobbies={hobbies} files={userFiles} />
			<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
				<DialogTitle id="simple-dialog-title">Loading...		<CircularProgress />
				</DialogTitle>
			</Dialog>
		</div>
	);
};
export default AboutMe;
