import React from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
// import {Autocomplete} from '@material-ui/lab';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		// width: '93%',
		position: 'absolute',
		top: '18%',
		right: '10px',
		background: '#FFFFFF',
		color: '#000000',
		// right: "1%",
		left: '0.5%',
		width: '96%',

		'@media (min-width: 415px)': {
			left: '19%',
			right: '15%',
			width: '91%',
			top: '12%',
		},
	},
}));

export default function Success(props) {
	console.log('triggered...');
	const classes = useStyles();
	// const [open, setOpen] = React.useState(false); // Will come from parent

	// const handleClick = () => {
	// 	setOpen(true);
	// };

	// const handleClose = (event, reason) => {
	// 	if (reason === "clickaway") {
	// 		return;
	// 	}

	// 	setOpen(false);
	// };

	return (
		<div className={classes.root}>
			{/* <Button variant="outlined" onClick={handleClick}>
				Open success snackbar
			</Button> */}
			<Snackbar
				className={classes.root}
				open={props.open} // Will come from parent
				autoHideDuration={6000}
				onClose={props.handleClose} // Will come from parent
				// anchorOrigin={{ vertical, horizontal }}
			>
				<Alert
					className={classes.root}
					onClose={props.handleClose}
					severity="success"

				>
					
					{props.SuccessMsg}
					{/* Success! Your post has been submitted.  */}
				</Alert>
			</Snackbar>
		</div>
	);
}
