import React, { useState, useEffect } from 'react';
//import {v4 as uuidv4} from 'uuid';
import Card from 'react-bootstrap/Card';
import lambdaConfig from "../../aws-exports-lambda.js";
import getCurrentUser from "../../utils";
import DeleteIcon from '@material-ui/icons/Delete';

const CommentList = (props) => {
	const [comment, setComment] = useState({});
	const [user, setUser] = useState([]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		setComment(props.data)
		// eslint-disable-next-line no-undef
		const apigClient = apigClientFactory.newClient(
			lambdaConfig
		);
		const currentUser = await getCurrentUser();
		const additionalParams = {
			queryParams: {
				user: currentUser
			}
		};
		const userParams = {
			userId: props.data.user_id,
		}
		apigClient.userUserIdGet(userParams, null, additionalParams)
			.then((res) => {
				setUser(res.data)
			})
			.catch((err) => {
				console.log(err);
			});
	}, [props.data]);

	return (
		<Card className="comment">
			<img className="comment-img" src={user.image_url ? user.image_url : '/image/Male 2.png'} alt="User Avatar" style={{ width: '50px', height: '50px', borderRadius: '100%' }}
			/>
			<div className="comment-text">
				<p>
					{' '}
					<b id="comment-username">{user.user_name}</b>
					{comment.comment_content}
				</p>
				{props.role === "Admin" ?
					<div style={{ display: 'flex' }}>
						<div tabIndex="0" style={{ display: 'flex', alignItems: 'center', marginLeft: '15px', cursor: 'pointer' }} onClick={() => props.deleteComment(comment)}>
							<DeleteIcon />
							<p>Delete</p>
						</div>
					</div>
					: null}
			</div>
		</Card>
	);
};
export default CommentList;
